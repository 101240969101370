import React from 'react';
import {Modal, Grid, Stack, Box, Card, CardContent, Button, createTheme, ThemeProvider, Divider } from '@mui/material';
import { Typography, CssBaseline, Container} from '@mui/material'
import { ethers } from 'ethers';
import { getOptionsByOwner } from './supaBaseFuncs';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 250,
    borderRadius: 1,
    boxShadow: 0,
    bgcolor: "transparent",
    p: 1,
    outline: "none",
  };
  
export function SelectNFT(props) {

    const [open, setOpen] = React.useState(false);
    const [myOptions, setMyOptions] = React.useState([]);

    const handleClose = () => setOpen(false);

const [ownedOptions, setOwnedOptions] = React.useState([]);



const handleOpen = () => {
    async function getOp() {
        console.log("getting options for userAccount", userAccount);
        const ops = await getOptionsByOwner(userAccount);
        setMyOptions(ops);
        console.log(ops);        
    }
    getOp();
    setOpen(true)
};

const setModalSelectedNFT = props.setModalSelectedNFT;
const selectedNFT = props.selectedNFT;
const modalVisisble = props.modalVisisble;
const setModalVisible = props.setModalVisible;
const userAccount = props.userAccount;
const switchPC = props.switchPC;
const modalSelectedNFT = props.modalSelectedNFT;
const filterContract = props.filterContract;
const filterStrike = props.filterStrike;
const filterIsCall = props.filterIsCall;
const filterExpiry = props.filterExpiry;
const orderId = props.orderId;

function handleSelectNFT(_orderId, optionId){
    console.log("selected:",_orderId, optionId);
    let obj = {...modalSelectedNFT};
    obj[_orderId] = optionId;
    setModalSelectedNFT(obj);
    handleClose();
    console.log('momo', modalSelectedNFT);
}

return (
    <div>

    
    <Button variant="outlined"  size="small"
        color={switchPC == 'Call' ? 'primary': 'secondary'}
        sx={{width:'100%', marginBottom:1, marginTop:'auto', fontSize:10}}
        onClick={() => handleOpen()}
        >
            {
                modalSelectedNFT[orderId] ?
                `#${modalSelectedNFT[orderId]}` :
                'Select'
            }
    </Button>
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >

        <Box style={{minWidth:'300px',maxWidth:'90%', maxHeight:'250px'}}
        sx={style}>
            <Card style={{maxWidth:'100%'}}>
                <CardContent>
                    
                    { myOptions.length == 0 ?
                    <div>
                        <Typography>
                            You dont own any options matching these critiria - Write a new option
                        </Typography>
                    </div>
                    
                    : 
                    
                    <div>
                    <Typography style={{fontSize:14}}>
                        Select option
                    </Typography>
                    <Divider></Divider>
                    </div>
                        }


                { myOptions.length == 0 ?
                    ''
                    :
                    <Grid>
                    <Grid item>
                        <Typography style={{fontSize:12}}>
                            Underlying {'  '}
                            {String(filterContract).slice(0, 6)}...{String(filterContract).slice(
                                            String(filterContract).length-6,
                                            String(filterContract).length)}
                                            {' OS, Blur, ME'}
                        </Typography>
                    </Grid>
                    {myOptions.map(
                        (e, index)=> {
                            return (
                                <Grid item>
                                    
                                    <Stack noWrap direction="row"
                                    sx={{display:'flex', justifyContent:'space-between'}}
                                    >
                                        <Box sx={{fontSize:10, padding:0.3, 
                                            margin:0.1, border:1, borderRadius:0.6, 
                                            minWidth:'25px', maxWidth:'45px', maxHeight:'20px',
                                            display:'flex', justifyContent:'center', alignContent:'center'}}>
                                            #{e.optionId}
                                        </Box>
                                        <Typography style={{fontSize:12}}>
                                        
                                        
                                        
                                        {'  '}
                                            {' '}{e.isCall ? 'Call' : 'Put'} @ {String(ethers.formatEther(String(e.strike)))}
                                                {' /'} {e.expiry}
                                        </Typography>

                                        <Button sx={{fontSize:10, padding:0, margin:0.3, borderRadius:0.5}}
                                        onClick={()=>{
                                            handleSelectNFT(orderId, e.optionId);
                                            }}>
                                            Select
                                        </Button>
                                    </Stack>
                                    
                                    <Divider></Divider>
                                </Grid>
                                
                            );
                        }
                    )}

                    </Grid>
                    }
                    
                    
                    
                    
                </CardContent>
            </Card>
        </Box>
    </Modal>
</div>
);
}
