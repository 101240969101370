import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import OrderCard from './OrderCard';

const AnimatedOrderCard = ({ theme, order, isBid }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (order && order.price) {
      setAnimate(true);
      const timer = setTimeout(() => setAnimate(false), 1000); // 1 second animation
      return () => clearTimeout(timer);
    }
  }, [order?.price]);

  return (
    <Box
      sx={{
        transition: 'background-color 0.5s ease',
        backgroundColor: animate ? 'lightgreen' : 'transparent',
        minWidth:'100%',
        maxWidth:'100%'
      }}
    >
      <OrderCard theme={theme} order={order} isBid={isBid} />
    </Box>
  );
};

export default AnimatedOrderCard;