import React from 'react';
import {Helmet} from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material';
//import { styled } from '@mui/material/styles';
import useStyles from '../styles';
import { Typography, Button, IconButton, Stack, Box, Card, CssBaseline, Container, AppBar, Toolbar, ButtonGroup} from '@mui/material'

//import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AppBarModule} from '../AppBarModuleFP';
import {AppBarFooter} from '../AppBarFooter';
import SvgComponent from '../optionMetadata/OptionSVG';
import ZtrikeIcon from '../ZtrikeIcon.js' ;

import OSwhite from '../OSwhite.svg' ;
import OSblack from '../OSblack.svg' ;

import ESlogo from '../etherscan-logo-light-circle.svg';
import ESlogoBlack from '../etherscan-logo-dark.svg';

import Marketplace from '../Sponsor-amico.svg';
import TinyDino from '../Online gallery-amico.svg';
//import ZtrikePattern from '../ZvgPatternAnimated.svg';
import ZtrikePatternDark from '../ZvgPatternDark.svg';
import ZtrikePattern2 from '../ZvgPattern2.svg';

import ZtrikePattern from '../ZtrikePattern.js';

import ZtrikeName from '../ZvgZtrikeNameDark.svg';
import {Twitter} from '@mui/icons-material';
import Icon from '@material-ui/core/Icon';

import { useState } from 'react';

import { Link } from "react-router-dom";


import * as ReactDOMServer from "react-dom/server";


const App = () => {

    const [mode, setMode] = React.useState('light');
    const btnColor = mode == 'light' ? 'black' : 'white';

    function handleMode(){
        if (mode=="light"){
            setMode("dark");
        } else {
            setMode("light");
        }
    }


    const theme = React.useMemo(
      () => createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
                // Light mode
                primary: {
                  light: '#757ce8',
                  main: '#264a39',
                  dark: '#607d8b',
                  contrastText: '#fff',
                },
                secondary: {
                  light: '#ff7961',
                  main: '#f44336',
                  dark: '#e64a19',
                  contrastText: '#000',
                },
                background: {
                  default: '#fcfcfc',
                  paper: '#dbdbdb',
                },
              }
            : {
                // Dark mode
                primary: {
                  light: '#757ce8',
                  main: '#264a39',
                  dark: '#607d8b',
                  contrastText: '#fff',
                },
                secondary: {
                  light: '#ff7961',
                  main: '#f44336',
                  dark: '#e64a19',
                  contrastText: '#000',
                },
                background: {
                  default: '#414345',
                  paper: '#232526',
                },
              }),
        },
        typography: {
          fontFamily: 'Inter',
        },
        shape: {
          borderRadius: 12,
        },
        custom: {
          gradient1: mode === 'dark'
            ? "radial-gradient(circle at top, #414345, #232526)"
            : "radial-gradient(circle at top, #fcfcfc, #dbdbdb)",
        },
      }), [mode]);

const [erc721OptionAddress, setOption] = useState('0x0792e69D2d3449895f047177E8Df2beEB05B09A6')
//const [scrollY, setScrollY] = useState(0);

/*React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);*/


const gradient1 = "radial-gradient(circle at top, #414345, #232526)";


// magic
const svgString = encodeURIComponent(
  ReactDOMServer.renderToStaticMarkup(
        <ZtrikePattern 
        lightColor = {mode=='light' ? '#dbdbdb' : '#232526'} //'#414345'
        darkColor = {mode=='light' ? '#ababab' : '#414345'} //'#fcfcfc'
        opacity = "0.2"
        />
    )
);



// const cards1 = [1, 2]; // Cards for option list

    const classes = useStyles(theme);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ztrike | NFT Options </title>
                <style>{'body { background-color: black; }'}</style>
            </Helmet>
            
            
            
            <div style={{ background: theme.custom.gradient1}} >
            
                <AppBarModule
                handleMode={handleMode}
                mode={mode}
                />
                
                <main >
                
                    <div  style ={ { backgroundImage: `url("data:image/svg+xml,${svgString}")`, //`url(${ZtrikePattern})`, 
                                    backgroundRepeat:'repeat', 
                                    backgroundPosition: 'center left 0px', 
                                    btransform: 'rotate(-10deg)', 
                                    backgroundSize:'60px',
                                    backgroundAttachment: 'fixed',
                                    backgroundOpacity:'1'} }>
                   <Box sx={{ height: '100vh' }} 
        display="flex" 
        flexDirection="column">
                    <div className={classes.container2}>
                    <div>

                                     
                        </div>  
                        <br/><br/>                        
                        <Container maxWidth='md' sx={{marginLeft:{md:'23%', sm:'5%'}}}>
                                <br/>
                               
                                    <Stack direction="row" spacing={2}>
                                        <SvgComponent strike='89.5'  contract='0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d' nftId='9999' writer='0xE4F5fb6Ef9494917d09e20E1B87413907Bdf7903' expiry='7140442' type='Put'/>
                                        <Box sx={{maxWidth: {xs:'37%',sm:'50%'} }}>
                                              
                                                <Typography sx={{ typography: { xs: 'h5', sm: 'h4', md: 'h2' } }} >
                                                The NFT Option Protocol
                                                </Typography>
                                                <br/>
                                                <Typography variant='body' >
                                                Manage risk, leverage your conviction, and earn yield on any NFT
                                                
                                                </Typography>

                                        </Box>
                                    </Stack>                            
                            
                        
                        </Container>
                        </div>
                    
                                <Box   display="flex" justifyContent="center" alignItems="center">
                                    <Button component={Link} to="/app" variant='outlined' 
                                    style={{maxWidth: '50%',  minWidth: '50%'}}
                                    sx={{borderColor:btnColor, color:btnColor}}>
                                        Launch App </Button>
                                </Box>

                                <Box display="flex" justifyContent="center"  alignItems="center" sx={{paddingTop:2}}>
                                    <Stack direction="row" spacing={2}>
                                        <IconButton onClick={() => window.open('https://google.com')}><img src={mode === 'light' ? OSblack : OSwhite} width={30} /></IconButton>
                                        <IconButton onClick={() => window.open('https://goerli.etherscan.io/address/0x7002cC4e2b8b539E71c9BcEe8529c6AED17B8E96')}><img src={mode === 'light' ?  ESlogoBlack : ESlogo} width={30}/></IconButton>
                                        <IconButton  onClick={() => window.open('https://twitter.com/ztrike_io')}>
                                        <Twitter fontSize='large' fill={btnColor}/>  
                                        </IconButton>
                                        
                                    </Stack>
                                </Box>
                                

                                <div>
                 
                            </div>  
                            </Box>
                </div>
                
                </main>
                
                

            { true ? '':


           
            <AppBar position="static" sx={{ top: 'auto', bottom: 0, height:'10vh', marginTop:'-5vh'}}>
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    

                                <Stack direction="row" spacing={2}>
                                <ButtonGroup>
                                <Button variant="outlined" color='primary' component={Link} to="/app" > <Typography variant='overline' >App</Typography></Button>
                                <Button variant="outlined" color='primary' component={Link} to="/app" ><Typography variant='overline' >Docs</Typography></Button>
                                <Button variant="outlined" color='primary' component={Link} to="/app" ><Typography variant='overline' >Calc</Typography></Button>
                                </ButtonGroup>
                                
                                </Stack>
                                

                        <Stack direction="row" spacing={2}>
                                <IconButton onClick={() => window.open('https://opensea.io')}><img src={mode === 'light' ? OSblack : OSwhite} width={20} /></IconButton>
                                <IconButton onClick={() => window.open(`https://rinkeby.etherscan.io/address/${erc721OptionAddress}`)}><img src={mode === 'light' ?  ESlogoBlack : ESlogo} width={20}/></IconButton>
                                <IconButton  onClick={() => window.open('https://twitter.com/ztrike_io')}>
                                        <Twitter fontSize='medium'/>  
                                </IconButton>
                        </Stack>
                </Toolbar>     
            </AppBar>
             }
            </div>
            
        </ThemeProvider>
    );
}

export default App;