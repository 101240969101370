import React from 'react'
import { Icon } from "@material-ui/core";
import ethlogo from './ethlogo.svg'

export const LogoSmall = () => (

    <Icon sx={{lineHeight:0}}>
        <img src={ethlogo} height={15} width={15} />
    </Icon>
)


export const LogoSmallCustom = (lineHeight, height, width) => (
    
    <Icon sx={{lineHeight:{lineHeight}}}>
        <img src={ethlogo} height={height} width={width} />
    </Icon>
)