import * as React from "react";
import Modal from "@mui/material/Modal";
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import {Logo} from './ethlogo.js';
import {LogoSmallCustom, LogoSmall} from './ethlogoSmall.js';
import InputAdornment from '@mui/material/InputAdornment';

import {Twitter, Percent, HourglassTop} from '@mui/icons-material';
import OSwhite from './OSwhite.svg' ;
import ESlogo from './etherscan-logo-light-circle.svg';

import {ethCreateOfferInitializeWallet, createOrder, createGeneralBid} from './ethCreateOffer.js';
import IOSSwitch from "./IOSwitch.js";

import { useConnectWallet } from '@web3-onboard/react';
import { initializeWallet, convertBlocksToTime } from "./Etherfuncs.js";

import { blackScholes } from "./blackScholes.js";

import {
  Typography,
  Card,
  Grid,
  Box,
  Stack,
  TextField,
  Button,
  Link
} from "@mui/material";
import { NFTAddress } from "./ethContracts.js";

const gradient1 = "radial-gradient(circle at top, #414345, #232526)";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  outline: "none",
};



export default function ModalCreateOrder(props) {



  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

  const [loadingStatus, setLoadingStatus] = React.useState({visible:false,
                                                             loadingText:''});

  React.useEffect(() => {
    if (wallet) {
      initializeWallet(wallet);
      ethCreateOfferInitializeWallet(wallet);
    }
  }, [wallet]);


  //const isRP = props.isRP;
  const buttonText = props.buttonText;
  const activeStrike = props.activeStrike;
  const justifyContent = props.justifyContent;
  const marginTop = justifyContent == 'center' ? '10px' : '0px';
  const underlyingContract= props.underlyingContract;
  const activeExpiry = props.activeExpiry;  // optionExpiry
  const activePC = props.activePC;
  const orderType = props.orderType;
  //const calcOpen = props.calcOpen;
  //const setCalcOpen = props.setCalcOpen;
  const bb = props.bb;
  const specificBid = props.specificBid;
  
  //const calchandleOpen = props.handleOpen;
  //const calchandleClose = props.handleClose;
  const activeUnderlyingID = props.activeUnderlyingID;
  const optionID = Number(props.optionID);
  const buttonColor = props.buttonColor;
  //let disabled = props.disabled;
  const disabled = props.disabled;
  //console.log(optionID, disabled);
  // Replace with external Black Scholes Function
  //const [cFloor, setCfloor] = React.useState(activeStrike);
  const [cStrike, setCstrike] = React.useState(0.05);
  //const [cOptionExpiry, setCOptionExpiry] = React.useState(1000000);
  //const [cVol, setCvol] = React.useState(175);
  const [cExp, setCexp] = React.useState(200000);
  const [cValue, setCValue] = React.useState(0)

  const [gContract, setgContract] = React.useState(NFTAddress);


  //  bs.blackScholes(cFloor, cStrike, cExp/2302080, cVol/100, .05, activePC.toLowerCase()).toFixed(3))
  
  //React.useEffect( () => {
  //  bs = blackScholes(cFloor, cStrike, cExp, cVol/100, .05, activePC)
  //  setCValue(bs);
  //},
  //[cFloor, cStrike, cExp, cVol]);


  const [oPremium, setOPremium] = React.useState(cValue);
  const [oExp, setOExp] = React.useState(20000); // orderExpiry
  const [isSellOrder, setIsSellOrder] = React.useState(String(orderType).toLowerCase() === 'offer');
  const [isCall, setIsCall] = React.useState(String(activePC).toLowerCase() == 'call');

  //const [optionID, setOptionID] = React.useState(0);
  //React.useEffect( () => {
  //  setCfloor(activeStrike);
  //  setCstrike(activeStrike);
  //  setCexp(Math.max(activeExpiry - bb, 1));
  //}, [activeStrike, activeExpiry]);

  const [openT, setOpenT] = React.useState(false);
  //const handleOpen = () => setOpenT(true);
  //const handleClose = () => setOpenT(false);

  function handleUpdate(){
    setOpenT(true);
    //setCfloor(activeStrike);
    //setCstrike(activeStrike);
    //setCexp(activeExpiry);
  }

  function toggleIsSell(){
    setIsSellOrder(!isSellOrder);
  }
  function toggleIsCall(){
    setIsCall(!isCall);
  }

  //const pdTop = isRP? 10 : 10;
  //const mgTop = isRP? 5 : 5;

  //console.log("underlying contr", underlyingContract, typeof(optionID));
  return (
    <div style={{margin:7,
                marginTop:0,
                justifyContent:justifyContent, 
                width:'100%',
                display: 'flex'}}>
        <Button variant="outlind" size="small" disabled={disabled} 
        onClick={() => handleUpdate()} color = {props.switchPC == 'Call' ? 'primary': 'secondary'}
        sx={{border:1, borderColor:buttonColor, width:'100%'}}>
          <Typography sx={{fontSize:12, color:buttonColor}}>
          {buttonText}
          </Typography>
        
        </Button>
      <Modal
        open={openT}
        onClose={() => setOpenT(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding:1}}>
                                    <Card sx={{maxWidth:'100%', padding:2}}>

                                   
                                   <Typography variant="h5">
                                    { specificBid ? 
                                    '' :
                                      <IOSSwitch
                                    switchPC = {isSellOrder ? 'Put' : 'Call'}
                                    handlePCswitch = {toggleIsSell}
                                    isMintForm = {false}
                                    checkedText = ""
                                    uncheckedText = ""
                                    />
                                    
                                    } {isSellOrder ? 'Order to Sell' : 'Order to Buy'} 
                                    
                                    </Typography>

                                    {isSellOrder && (typeof(optionID)!='number') ? 
                                    <Button variant="outlined">
                                      Select Option to Sell
                                    </Button>
                                    : ''} 

                                    {isSellOrder  && (typeof(optionID)!='number') ? <br/> : ''}
                                    {isSellOrder && (typeof(optionID)!='number') ? <br/> : ''}
                                    <Divider textAlign="left"> {isSellOrder ? `Option ID #${String(optionID)}` : 'Details'}</Divider>
                                    
                                    <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                                        <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
                                            alignItems="stretch" columns={{ xs: 2, sm: 2, md: 2 }}>
                                                
                                                <Grid item xs={2} >
                                                  {isSellOrder | specificBid ? 
                                                <Typography align="center" variant='caption' >
                                                Option type: Call 
                                                </Typography>
                                                :
                                            <IOSSwitch
                                              switchPC = {!isCall ? 'Put' : 'Call'}
                                              handlePCswitch = {toggleIsCall}
                                              isMintForm = {false}
                                              checkedText = "Call"
                                              uncheckedText = "Put"
                                              />

                                                }
                                                    
                                                    <br/>

                                                    {isSellOrder | specificBid ? 
                                                      <Typography align="center" variant='caption' >
                                                        Underlying ID: #{activeUnderlyingID} 
                                                        <Link
                                                        target="_blank" 
                                                        href={`https://testnets.opensea.io/assets/sepolia/${underlyingContract}/${activeUnderlyingID}`}
                                                        >
                                                          <img src={OSwhite} width={15} style={{marginLeft:8, marginBottom:'-5px'}}/>
                                                        </Link>
                                                      </Typography>
                                                    :
                                                    ''
                                                    }
                                                    {isSellOrder | specificBid ? <br/> : ''}
                                                    {isSellOrder | specificBid ? 
                                                    <Typography align="center" variant='caption' >
                                                        Contract address:  
                                                        <Link color={(activePC === 'call') ? 'primary': 'secondary'} 
                                                        style={{paddingLeft:4}}
                                                        target="_blank" 
                                                        href={`https://sepolia.etherscan.io/address/${underlyingContract}`}> 
                                                        {String(underlyingContract).substring(0,5) + '...' + String(underlyingContract).substring(38,50)+'  '} 
                                                       
                                                        </Link> 

                                                        <Link
                                                        target="_blank" 
                                                        href={`https://testnets.opensea.io/assets/sepolia/${underlyingContract}`}
                                                        >
                                                          <img src={OSwhite} width={15} style={{marginLeft:8, marginBottom:'-5px'}}/>
                                                        </Link>
                                                        <Link
                                                        target="_blank" 
                                                        href={`https://sepolia.etherscan.io/address/${underlyingContract}`}
                                                        >
                                                          <img src={ESlogo} width={15} style={{marginLeft:8, marginBottom:'-5px'}}/>
                                                        </Link>
                                                        
                                                        
                                                    </Typography>
                                                     : 
                                                     <TextField id="outlined-basic" label="Underlying Contract" fullWidth
                                                      variant="outlined" 
                                                      defaultValue={gContract}
                                                      onChange={e => setgContract(e.target.value)}
                                                      sx={{paddingBottom:2}}/>   
                                                     
                                                     }
                                                    
                                                    {isSellOrder| specificBid ? <br/> : ''}
                                                    {isSellOrder| specificBid ?
                                                    <Typography align="center" variant='caption' 
                                                    sx={{height:0, display:'flex', 
                                                        alignContent:'center', 
                                                        margin:0, padding:0}}>
                                                    Strike:
                                                    <Box sx={{height:24, width:24, marginTop:'-5px'}}>
                                                       <LogoSmall />
                                                    </Box>
                                                     
                                                      {activeStrike}
                                                </Typography>
                                                    : 
                                                    <TextField id="outlined-basic" label="Strike (ETH)" fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                        <InputAdornment position="start"><Logo /></InputAdornment>
                                                        ),
                                                    }} variant="outlined" 
                                                    defaultValue={cStrike}
                                                    onChange={e => setCstrike(e.target.value)}
                                                    sx={{paddingBottom:2}}
                                                    />  
                                                    }
                                                    
                                                    {isSellOrder | specificBid ? <br/> : ''}
                                                    {isSellOrder | specificBid ? 
                                                    <Typography align="center" variant='caption' >
                                                      Expiry: {activeExpiry} ({activeExpiry-bb} left, ~{convertBlocksToTime(activeExpiry-bb)})
                                                    </Typography>
                                                    :  <TextField id="outlined-basic" label="Expiry (# blocks until)" fullWidth
                                                    variant="outlined" 
                                                    defaultValue={cExp}
                                                    onChange={e => setCexp(e.target.value)}
                                                    sx={{paddingBottom:2}}
                                                    />  }
                                                    
                                                    <Divider>
                                                      Order Details
                                                    </Divider>
                                                </Grid>
                                                
                                                
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label={isSellOrder ? "Offer (ETH)" : "Bid (ETH)"} fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><Logo /></InputAdornment>
                                                    ),
                                                }} variant="outlined" 
                                                defaultValue={oPremium}
                                                onChange={e => setOPremium(e.target.value)}/>     
                                                </Grid>

                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label="Order valid for (# Blocks)" fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><HourglassTop /></InputAdornment>
                                                    ),
                                                }} variant="outlined"
                                                defaultValue={oExp}
                                                onChange={e => setOExp(e.target.value)} />     
                                                </Grid>
                                                <Grid item xs={2} >
                                                  <Typography style={{fontSize:8}}>
                                                    {specificBid ? 'Bid for this optionID only' : `Bid for any option matching details ${optionID}`}
                                                  </Typography>
                                               
                                               { specificBid ?
                                                <Button variant="contained" style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                                    onClick={() => createOrder(optionID, oPremium, oExp, isCall, isSellOrder, setLoadingStatus)}>Create Order</Button>
                                               :
                                               <Button variant="contained" style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                               onClick={() => createGeneralBid(gContract, cStrike, isCall, cExp, oPremium, oExp, setLoadingStatus)}>Create bid</Button>
                                                  
                                                  }
                                                                                            
                                                </Grid>
                                                <Grid item xs={2} >
                                          { loadingStatus.loadingText != '' ? 
                                              <Box sx={{ width: '100%', minHeight:'10px' }}>
                                                <Typography sx={{fontSize:10}}>
                                                  {loadingStatus.loadingText}
                                                </Typography>
                                              </Box>
                                            : ''
                                              }
                                            { loadingStatus.visible ? 
                                              <Box sx={{ width: '100%', minHeight:'10px' }}>
                                                <LinearProgress color='primary'  />
                                              </Box>
                                            : ''
                                              }
                                                </Grid>

                                        </Grid>

                                    </Stack>
                                    </Card>
                                </Box>
        </Box>
      </Modal>
    </div>
  );
}
