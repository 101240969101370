import { ethers } from 'ethers';
import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json';
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json';
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json';

import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';

import { getOrder, getGeneralBidDetails, getOption} from './supaBaseFuncs.js';

import { getBlockGas } from './supaBaseFuncs.js';

const INFURA_URL = process.env.REACT_APP_INFURA_URL;




// Wallet information singleton
let wallet = null;

export function ethCreateOfferInitializeWallet(walletInfo) {
  wallet = walletInfo;
}

async function getProvider(){
  if (wallet) {
    let account = wallet.accounts[0].address;
    const provider= new ethers.BrowserProvider(wallet.provider, 'any')
    const signer = await provider.getSigner()
    return [provider, signer, account];
}
}

async function requestAccount() {
  const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
  return [account];
  }


/*
struct Order{
    uint orderId;
    uint256 optionId; 
    address payable orderCreator;
    uint256 priceInWei; // 1 ether = 1*10^18 = 1000000000000000000 = 1_000_000_000_000_000_000
    bool isOffer; // 1==Offer or 2==Bid
    bool isFilled; // order was filled
    bool isCancelled; // order was cancelled
    uint validForNblocks; //
    uint expiryBlock;
*/

async function acceptOrder(orderId, optionId){
  if (wallet) {
    const [provider, signer, account] = await getProvider();
    const contractSigner = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
    //const contractProvider = new ethers.Contract(MarketplaceAddress, Marketplace.abi, provider);
    
    // get Order Details
    // get these from supabase instead
    //const order = await contractProvider.Orders(orderId);
    
    const order = await getOrder(orderId);
    console.log("order Fetched: >>>>", order);
    let oo = {
      'orderId':order[0]['_orderId'],
      'optionId':order[0]['optionId'],
      'orderCreator':order[0]['orderCreator'],
      'priceInWei':order[0]['price'],
      'expiryBlock':order[0]['validUntil'],
      'isOffer':order[0]['isOffer'],
      'isFilled':order[0]['isFilled'],
      'isCancelled':order[0]['isCancelled'],
      'isGeneral':order[0]['isGeneral']
      };

    /*
    struct Order{
        uint orderId;
        uint256 optionId;  // or GeneralBidOptionInfoID
        address payable orderCreator;
        uint256 priceInWei; // 1 ether = 1*10^18 = 1000000000000000000 = 1_000_000_000_000_000_000
        uint validUntil; // block at which order expires
        bool isOffer; // 1==Offer or 2==Bid
        bool isFilled; // order was filled
        bool isCancelled; // order was cancelled
        bool isGeneral;
    }

    // store the details of a isGeneral order
    struct GeneralBid{
        uint orderId;
        bool isCall;
        uint strike;
        uint expiry; // block at which Option expires
        address contractAddress;
    }
    */
    console.log(oo)
    if (oo.isOffer){
    // if order is offer -- acceptOffer
    console.log("Accepting offer")
    const priceInWei = oo.priceInWei;
    const wei = ethers.formatUnits(String(priceInWei),'wei');
    console.log("wei", wei);
    const acceptOfffer = await contractSigner.acceptOffer(Number(orderId), {value:wei});
    console.log("Waiting");
    await acceptOfffer.wait();
    console.log("Offer accepted")
    } else{

      if (oo.isGeneral){
        console.log("Not implemented yet -- generalBidFill")

        // get generalBid from supabase
        const gBid = getGeneralBidDetails(orderId);
        // maybe also get OptionId from supabase to check if everything matches before EVM rejection??
        const option = getOption(optionId);

        console.log("gBid", gBid);
        console.log("option", option);

        // make checks to see if details match so order can be accepted
        console.log("CRITICAL: missing implementation of detail checks");

        // call function to acceptBid
        const acceptBidGeneral = await contractSigner.acceptBidGeneral(orderId, optionId);
        console.log("Waiting ~ ");
        await acceptBidGeneral.wait();
      console.log("bid filled")

      } else {
      // if order is bid -- acceptBid 
      console.log("accepting bid")
      const acceptBid = await contractSigner.acceptBid(orderId);
      //acceptBidGeneral(uint _orderId, uint _optionId)
      //acceptBid(uint _orderId) 
      console.log("Waiting");
      await acceptBid.wait();

      }
    }

  }
}


async function cancelOrder(_orderId, isOffer) {
  if (wallet) {
    const [provider, signer, account] = await getProvider();
    const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
    console.log("trying cancel Order:", _orderId, isOffer);


    if (isOffer) {
      const cancelOrder = await contract.cancelOffer(_orderId);
      console.log("Waiting");
      await cancelOrder.wait();
    } else {
      const cancelOrder = await contract.cancelBid(_orderId);
      console.log("Waiting");
      await cancelOrder.wait();
    }
  }
  console.log("Connect metamask");
}


async function getCurrentBlock() {
  const [bb, fGas] = await getBlockGas();
  console.log("blockGasInfo", bb);
  return bb;
}

async function createGeneralBid(underlyingAddress, strike, isCall, expiry, bid, validForNBlocks, setLoadingStatus){
  try {
    setLoadingStatus({visible:true,
      loadingText:`Creating order to buy any ${isCall ? 'call' : 'put'} option with strike ${strike} on underlying collection ${underlyingAddress} for Ξ${bid} in the next ${validForNBlocks} blocks`});
  
  
  if (wallet) {
    const [provider, signer, account] = await getProvider();
    const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
    console.log("trying createBidGeneral with args:", underlyingAddress,strike,isCall,expiry,bid, validForNBlocks);
  // require that expiry is in the future
  const currentBlock = await getCurrentBlock();
  
  const expiryBlock = Number(expiry) + Number(currentBlock);
  console.log("expiryBlock", expiryBlock, expiry, currentBlock);
  
  const bidCreate = await contract.createBidGeneral(underlyingAddress,
                                                    ethers.parseEther(String(strike)) ,
                                                    isCall,
                                                    expiryBlock,
                                                    ethers.parseEther(String(bid)), 
                                                    validForNBlocks, {value: ethers.parseEther(String(bid))});
  console.log("Waiting");
  await bidCreate.wait();
  console.log("general bid created")
  setLoadingStatus({visible:false, loadingText:'Order created'});
  }
} catch {
  setLoadingStatus({visible:false,
    loadingText:'Order creation failed for general bid'});
  }
}

async function createOrder(optionId, priceInETH, validForNblocks, isCall, isSell, setLoadingStatus){
  console.log("createOrder", optionId, priceInETH, validForNblocks, isCall, isSell);
  try {
      setLoadingStatus({visible:true,
        loadingText:`Creating order to ${isSell ? 'sell' : 'buy'} option ${optionId}  @ Ξ${priceInETH} in the next ${validForNblocks} blocks`});
    
    const priceInWei = ethers.parseUnits(priceInETH, "ether");
    console.log('ETH', priceInETH, 'wei', priceInWei);
     
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      //const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
      // if not already approved, approve alle Ztrike options for Marketplace contract
      console.log("account", account, ZtrikeAddress, "signer", signer);
      const ztrikeContract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
      const ztrikeContractProvider = new ethers.Contract(ZtrikeAddress, Option.abi, provider);
      console.log("trying approve", account, MarketplaceAddress);
      const isApproved = await ztrikeContractProvider.isApprovedForAll(account, MarketplaceAddress);
      //await isApproved.wait();
      console.log("isApproved", isApproved);

      if (false){
        const approval = await ztrikeContract.setApprovalForAll(MarketplaceAddress, true);
        await approval.wait();
        console.log("approved");
      } else {
        console.log("already approved");
      };
      
      
      const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
      //console.log(`trying 1 ${ZtrikeAddress}`);
      //const transation = await contract.setOptionContractAddress(ZtrikeAddress);
      //console.log("trying 2");
      //await transation.wait();
      //console.log(`Marketplace: Option contract succesfully set to ${ZtrikeAddress}`);

      if (isSell){
          console.log("trying createOffer with args:", optionId, priceInWei, validForNblocks);
          setLoadingStatus({visible:true, loadingText:`Creating transaction to sell option ${optionId} @ Ξ${priceInETH} in the next ${validForNblocks} blocks`});
          const offerCreate = await contract.createOffer(optionId, priceInWei, validForNblocks);
          console.log("Waiting");
          await offerCreate.wait();
          console.log("Offer created")
          setLoadingStatus({visible:false, loadingText:'Order created'});
      } else {
          console.log("trying createBidSpecifc with args:", optionId, priceInWei, validForNblocks);
          setLoadingStatus({visible:true, loadingText:`Creating transaction to buy option ${optionId} @ Ξ${priceInETH} in the next ${validForNblocks} blocks`});
          const bidCreate = await contract.createBidSpecifc(optionId, priceInWei, String(validForNblocks),
        {value: priceInWei});
          console.log("Waiting");
          await bidCreate.wait();
          console.log("specific bid created")
          setLoadingStatus({visible:false, loadingText:'Order created'});
      }
      
      
    }
  } catch {
    setLoadingStatus({visible:false,
      loadingText:'Order creation failed'});
  }

    }

    export {createOrder, createGeneralBid, acceptOrder, getOrder, cancelOrder, getCurrentBlock};

