
import * as React from 'react';
import {Box, IconButton,FormGroup, FormControlLabel, Checkbox, Card, CardContent, Stack, Divider, Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Typography, ButtonGroup} from '@mui/material';
//import {SelectNFTmodal} from './ModalSwipe';
import { ethers } from 'ethers'
//import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json'
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useState, useEffect } from 'react';

import SearchableContractInput from './contractSelectDropdown.js';

import { SelectNFT } from './OrderViewSelectNFT.js';

import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';

import ModalCreateOrder from './ModalCreateOrder';
import {ethCreateOfferInitializeWallet, createOrder, acceptOrder, getOrder, cancelOrder} from './ethCreateOffer.js';
import { useConnectWallet } from '@web3-onboard/react'

import { getOrders, getBlockGas, supabase,getUniqueUnderlyingFromOrders } from './supaBaseFuncs.js';

import { convertBlocksToTime } from './Etherfuncs.js';

const INFURA_URL = process.env.REACT_APP_INFURA_URL;

export function OrdersView(props) {
    
    const theme = props.theme;

    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

    React.useEffect(() => {
      if (wallet) {
        ethCreateOfferInitializeWallet(wallet);
      }
    }, [wallet]);

      const switchPC = props.switchPC;
      const userAccount = props.userAccount;

      const [MyOrders, setMyOrders] = useState([]);
      const [uniqueContracts, setUniqueContracts] = useState(['Nothing']);
      const [curr, setCurr] = React.useState(0);
      const [currGas, setCurrGas] = React.useState(0);
      const [filterOn, setFilterOn] = React.useState(false);
    
      
      const [filterContract, setFilterContract] = useState('');
      const [filterCreator, setFilterCreator] = useState('');
      const [showExpired, setShowExpired] = useState(false);
      const [showCancelled, setShowCancelled] = useState(false);
      const [filteredOrders, setFilteredOrders] = useState([]);

      // Select NFT modal
      const [modalVisisble, setModalVisible] =  useState(false);
      const [modalSelectedNFT, setModalSelectedNFT] = useState({0: ''});

      const fetchBlock = async () => {
        let [bb, fGas] = await getBlockGas();
        console.log("GETTING BLOCKNO...", bb, fGas);
        setCurrGas(fGas);
        setCurr(bb);
    }
    

    function toggleFilter(){
        setFilterOn(!filterOn);
    }

    
    useEffect(() => {
        console.log("supabase: SUBSCRIBING TO CHANNEL orders");
        const channels = supabase.channel('orders-channel')
        .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'orders' },
        (payload) => {
            console.log('supabase: Change received for orders!', payload);
            if (payload.eventType === 'INSERT') {
                console.log('supabase: New order inserted:', payload.new);
            } else if (payload.eventType === 'UPDATE') {
                console.log('supabase: Order updated:', payload.new);
            } else if (payload.eventType === 'DELETE') {
                console.log('supabase: Order deleted:', payload.old);
            }
            GetOrders();
        }
        )
        .subscribe((status) => {
            console.log('supabase: Subscription status for orders:', status);
        })
      
        return () => {
            console.log('supabase: Unsubscribing from orders channel');
            channels.unsubscribe();
        };
    }, []);
    
    useEffect(() => {
        console.log("supabase: SUBSCRIBING TO CHANNEL (block)");
        const channels = supabase.channel('ordersView-block-channel')
        .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'blockInfo' },
        (payload) => {
            console.log('supabase: blockInfo Change received!', payload);
            fetchBlock();
        }
        )
        .subscribe((status) => {
            console.log('supabase: Subscription status for blockInfo:', status);
        })
      
        return () => {
            console.log('supabase: Unsubscribing from blockInfo channel');
            channels.unsubscribe();
        };
    }, []);

      useEffect(() => {
        console.log("fetching blocks at startup");
            fetchBlock();

      }, []);

      
    async function getOptionParameters(optionId) {
        if (typeof window.ethereum !== 'undefined' | true) {
            const provider = new ethers.InfuraProvider("sepolia", INFURA_URL);
            const contract = new ethers.Contract(ZtrikeAddress, Option.abi, provider);   
            const opt = await contract.Options(optionId);
            //console.log("", opt);   
            const OptionParameters = {
                optionId:Number(opt[0]), 
                isCall:opt[1],
                strike:ethers.formatEther(opt[2]),
                expiry:Number(opt[3]),
                optWriter:opt[4],
                contractAddress:opt[5],
                underlyingId:Number(opt[6]),
            }; 
            //console.log(OptionParameters);
            return OptionParameters;
        }
    }


    async function addNewToMyOrders(g){

        const opt = await getOptionParameters(g.optiondId);
        const gg = {'_orderId':g._orderId,
            'optionId':g.optionId,
            'price':g.price,
            'isBid':g.isBid,
            'validUntil':g.validUntil,
            'orderCreator':g.orderCreator,
            'underlyingContract': opt.contractAddress,
            'underlyingId':opt.underlyingId,
            'cOptionId': opt.optionId,
            'strike':opt.strike,
            'expiry':opt.expiry,
            'isCall': opt.isCall,
        }

        //let newOrders = MyOrders;
        //newOrders.push(gg);
        setMyOrders(prevState => ([
            ...prevState,
            gg
        ]));
    }

// Modify your GetOrders function to return the fetched orders
async function GetOrders() {
    console.log("getting orders")
    const orders = await getOrders();
    setMyOrders(orders);

    // get unique orders
    console.log(orders);
    let unique = orders.map(item => item.underlyingContract)
    .filter((value, index, self) => self.indexOf(value) === index)
    console.log("unique", unique);
    setUniqueContracts(unique);

    return orders;
};

// Update your useEffect to use the returned orders
useEffect(() => {
    async function fetchOrders() {
        if (MyOrders.length === 0) {
            const fetchedOrders = await GetOrders();
            filterOrders(fetchedOrders);
        }
    }
    fetchOrders();
}, []); // run only once

// Create a separate function for filtering orders
function filterOrders(orders) {
    if (orders.length > 0) {
        const f = orders.filter(order => {
            const contractMatch = !filterContract || order.underlyingContract.toLowerCase() === filterContract.toLowerCase();
            const creatorMatch = !filterCreator || order.orderCreator.toLowerCase() === filterCreator.toLowerCase();
            const expiryMatch = showExpired || (Number(order.validUntil) - curr) >= 1;
            const cancelledMatch = showCancelled || !order.isCancelled;
            return contractMatch && creatorMatch && expiryMatch && cancelledMatch;
        });
        console.log(f);
        setFilteredOrders(f);
    }
}

// Update your first useEffect to use the filterOrders function
useEffect(() => {
    filterOrders(MyOrders);
}, [MyOrders, showExpired, showCancelled, filterContract, filterCreator, curr]);
    
    useEffect(() => {
        console.log("Updated myOrders");
    }, [MyOrders]);

    return(
    <Card sx={{maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, minWidth: {sm:'97%', sm:'90%', lg:'50%'}, paddingLeft:1, paddingRight:0}}>
      <CardContent sx={{paddingLeft:0, paddingRight:0,  marginLeft:1, marginRight:0}}>    
          
          <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
          alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>              
              <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <Typography variant="h6" align='left' style={{paddingBottom:0, paddingTop:10}}>
                    My orders ({filteredOrders.length})
                  </Typography>
                  { true ? '' : // hides the button if true
                  <Button variant='contained' color={switchPC == 'Call' ? 'primary': 'secondary'} 
                  style={{maxWidth: '100%', maxHeight: '50%', minWidth: '100%', minHeight: '50%'}}
                  onClick={() => GetOrders()}
                  disabled={ !ethers.isAddress(userAccount)}>
                    {( ethers.isAddress(userAccount)) ? 'Update' : 'Connect wallet'}
                  </Button> // end of buttonhiding 
                  } 
                    <Stack 
                    direction="row" 
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        paddingTop: 2,
                        paddingRight:1
                    }}
                    >
                    <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6.5, paddingRight:1 }}>
                        <IconButton onClick={() => toggleFilter()}>
                            {filterOn ?  <FilterListOffIcon/> : <FilterListIcon/>}
                        </IconButton>
                    </Box>
                   

                    <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6, marginRight:1 }}>
                        <ModalCreateOrder
                        switchPC={switchPC}
                        buttonText="New order"
                        justifyContent="flex-end"
                        buttonColor={'primary.main'}
                        />
                    </Box>
                    </Stack>
                
                    {filterOn ? 
                    <Divider textAlign="left" sx={{paddingBottom:2}}>Filter Orders</Divider>
                    : ''}
                {filterOn ? 
                <div>
                    <SearchableContractInput
                    contracts = {uniqueContracts}
                    onChange={setFilterContract}
                    label="Underlying Contract"
                    />
                    </div>
                    : ''}
                
                {filterOn ? 
                     <TextField id="outlined-basic" 
                     label="Order Creator" fullWidth
                     variant="outlined" 
                     size="small"
                     value={filterCreator}
                    onChange={(e) => setFilterCreator(e.target.value)}
                     sx={{paddingBottom:-1, paddingRight:1}}/>
                    : ''}  

               
                {filterOn ? 
                      <FormGroup>
                      <FormControlLabel 
                        control={
                          <Checkbox 
                            checked={showExpired} 
                            onChange={(e) => setShowExpired(e.target.checked)}
                          />
                        } 
                        label="Show expired orders" 
                      />
                       <FormControlLabel 
                        control={
                          <Checkbox 
                            checked={showCancelled} 
                            onChange={(e) => setShowCancelled(e.target.checked)}
                          />
                        } 
                        label="Show cancelled orders" 
                      />
                    </FormGroup>
                    : ''}
                {filterOn ? 
                     <Divider sx={{paddingTop:2}}/>
                    : ''}

                
              </Grid>

              <Grid container item xs={12} wrap='nowrap' style={{ paddingTop: 25, 
                                                maxHeight:'500px',
                                                overflowY:'hidden',
                                                overflowX: 'atuo',
                                                display: "flex",
                                                flexDirection:"row",
                                                maxWidth: '100%',
                                                scrollbarColor: '#282929 #6f7070',
                                                scrollbarWidth: 'thin',
                                                marginBottom: 0}}>
                {  filteredOrders.length == 0 ? 
                <Typography>
                    Loading...
                </Typography> 
                :

                
                filteredOrders.map( (e, index) => { 
                        return (
                            <Grid item xs={12} sm={10} md={6} style={{ padding: 3, marginBottom:10, minWidth:'190px'}} key={e._orderId} >
                                 <Card style={{backgroundImage: theme.custom.gradient1, 
                                    padding:2, height:'100%'}}>
                                    <Typography  variant='h5' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Order #{String(e._orderId)}
                                     </Typography>
                                    
                                    <Divider textAlign="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>
                                    <Typography  variant='body2'  align="left" sx={{fontSize:18}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        {!e.isOffer ? 'Bid to buy' : 'Offer to sell'} 
                                    </Typography>
                                    </Divider>
                                    
                                    
                                    <Typography  variant='body2' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  Price: Ξ{String(ethers.formatEther(String(e.price)))} </Typography>
                                    <Typography gutterBottom variant='body2' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  Valid until : {String(e.validUntil)} </Typography>
                                    <Typography gutterBottom variant='body2' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  {String(Number(e.validUntil) - curr)} blocks left </Typography>
                                    <Typography gutterBottom variant='body2' align="left" fontSize={8} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  ({convertBlocksToTime(Number(e.validUntil) - curr)}) </Typography>
                                    
                                    <Typography gutterBottom variant='body2' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}> Creator: {e.orderCreator.slice(0,6)}...{e.orderCreator.slice(e.orderCreator.length-4)} </Typography>
                                    {e.isFilled ? 
                                        <Typography gutterBottom variant='body' align="left" color='primary'> Order Filled </Typography>
                                        : '' }

                                    {(e.isCancelled) ? 
                                        <Typography gutterBottom variant='body' align="left" color="secondary"> Order Cancelled </Typography>
                                        : '' }

                                    {(  ((Number(e.validUntil) - curr) < 1) & !e.isFilled & !e.isCancelled) ? 
                                        <Typography gutterBottom variant='body' align="left" color="secondary"> Order Expired </Typography>
                                        : '' }
                                    { (e.isFilled | e.isCancelled) ? '' : 
                                    <br/>}
                                    
                                    <Divider textAlign="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}> 
                                    
                                    { e.isGeneral ?
                                    <Typography  variant='body' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        <Typography  variant='body' align="left" style={{fontSize:10}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                            Any option matching:
                                        </Typography>
                                    </Typography>
                                    : 
                                    <Typography  variant='body' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        {`Option #${String(e.optionId)}`} 
                                    </Typography>

                                    }
                                    
                                    
                                    </Divider>

                                    <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Type: {e.isCall ? 'Call' : 'Put'} </Typography>
                                    
                                    <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Contract {String(e.underlyingContract).slice(0,6)} ...{String(e.underlyingContract).slice(String(e.underlyingContract).length - 4)} </Typography>
                                    
                                    { e.isGeneral ? 
                                        <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>
                                        Any underlying ID
                                        </Typography>
                                    :
                                        <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>
                                              Underlying ID #{String(e.underlyingId)} 
                                        </Typography>

                                    }
                                    
                                    <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Strike Ξ{String(e.strike)} </Typography>
                                    <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Expiry {String(e.expiry)} ({String(Number(e.expiry) - curr)} left, {convertBlocksToTime(Number(e.expiry) - curr)}) </Typography>
                                    <Divider/>
                                    <Typography  variant='body2'  align="left" style={{fontSize:16}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"} sx={{fontSize:18}}>    
                                        Highest bid: Ξ12 </Typography>
                                   
                                {
                                    (String(e.orderCreator).toLowerCase() != String(userAccount).toLowerCase()) ? 
                                    (
                                     !e.isGeneral ?
                                            <Button variant="contained"  size="small"
                                            color={switchPC == 'Call' ? 'primary': 'secondary'}
                                            sx={{width:'100%', marginBottom:1, marginTop:'auto'}}
                                            onClick={() =>acceptOrder(e._orderId)}
                                            disabled = {e.isFilled 
                                                | e.isCancelled 
                                                | (Number(e.validUntil) - curr) < 1
                                                |  (String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase())
                                            }
                                            >
                                            Accept {e.isOffer ? 'offer' : 'bid'}
                                            </Button>

                                        :   

                                        <ButtonGroup sx={{width:'100%'}}>
                                            
                                            <SelectNFT
                                            modalVisisble = {modalVisisble}
                                            setModalVisible = {setModalVisible}
                                            setModalSelectedNFT = {setModalSelectedNFT}
                                            modalSelectedNFT = {modalSelectedNFT}
                                            userAccount = {userAccount}
                                            filterContract = {e.underlyingContract}
                                            filterIsCall = {e.isCall}
                                            filterStrike = {e.strike}
                                            filterExpiry = {e.expiry}
                                            orderId = {e._orderId}
                                            />
                                            

                                            <Button variant="contained"  size="small"
                                            color={switchPC == 'Call' ? 'primary': 'secondary'}
                                            sx={{width:'100%', marginBottom:1, marginTop:'auto', fontSize:10}}
                                            onClick={() =>acceptOrder(e._orderId, modalSelectedNFT[e._orderId])}
                                            disabled = { true | e.isFilled 
                                                | e.isCancelled 
                                                | (Number(e.validUntil) - curr) < 1
                                                |  (String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase())
                                            }
                                                >
                                                Accept
                                            </Button>
                                        </ButtonGroup>
                                    )
                                    : ''
                                }
                                     
                                    {
                                        (String(e.orderCreator).toLowerCase() != String(userAccount).toLowerCase()) ?
                                    <Button variant="outlined"  size="small"
                                                color={switchPC == 'Call' ? 'primary': 'secondary'}
                                                sx={{width:'100%'}}
                                                onClick={() => console.log("accept bid not implemented")}
                                                disabled = {e.isFilled  } // if order is already filled (we already sold), we assume we dont want to accept another bid
                                        >
                                        {e.isOffer ? 'Place bid' : 'Place offer'}
                                    </Button>
                                    : ''
                                    }
                                    {
                                        (String(e.orderCreator).toLowerCase() == String(userAccount).toLowerCase()) ?   
                                    <Button variant="outlined"  size="small"
                                                color={switchPC == 'Call' ? 'primary': 'secondary'}
                                                sx={{width:'100%'}}
                                                onClick={() => console.log("accept bid not implemented")}
                                                disabled = {e.isFilled} // if order is already filled (we already sold), we assume we dont want to accept another bid
                                        >
                                       {e.isOffer ? 'Accept highest bid' : 'Accept lowest offer'} 
                                    </Button>
                                    : ''
                                    }

                                    <Stack>
                                        <ButtonGroup variant="outlined"  size="small" fullWidth
                                                    color={switchPC == 'Call' ? 'primary': 'secondary'}
                                                    sx={{width:'100%', paddingTop:1}}
                                            >
                                            <Button 
                                            disabled = {e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1 | e.orderCreator.toLowerCase() != String(userAccount).toLowerCase()}
                                            > Edit 
                                            </Button>
                                            <Button 
                                            disabled = {e.isFilled | e.isCancelled | e.orderCreator.toLowerCase() != String(userAccount).toLowerCase()}
                                            onClick={ () => cancelOrder(e._orderId, e.isOffer)}
                                            > Cancel </Button>
                                        </ButtonGroup>
                                    </Stack>
                                    
                                    
                                 </Card>
                            </Grid>
                    ) })
                }
              </Grid>
                                        


          </Grid>
          
      </CardContent>
</Card>)};














