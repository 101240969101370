import * as React from 'react';
//import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
import {Menu} from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import { Typography, Grid, Container, Box, Button, Stack, IconButton} from '@mui/material'
import OSwhite from './OSwhite.svg' ;
import ESlogo from './etherscan-logo-light-circle.svg';

import {Twitter} from '@mui/icons-material';

import { Link } from "react-router-dom";

import { ExtraMenu } from './ExtraMenu';

const gradient1 = "radial-gradient(circle at top, #414345, #232526)";
const cards1 = [1, 2]; // Cards for option list
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  borderRadius: 1,
  boxShadow: 5,
  bgcolor: 'black',
  p: 1,
  outline:'none'
};

export function MainMenu(props) {

  const fakeBaycAddress = props.fakeBaycAddress;
  const erc721OptionAddress = props.erc721OptionAddress;
  const amount   = props.amount;
  const userAccount = props.userAccount;
  const setUserAccount = props.setUserAccount;
  const setAmount = props.setAmount;
  const setBAYC = props.setBAYC;
  const setOption = props.setOption;
  const switchPC = props.switchPC;
  const mode = props.mode;

  const menuColor = mode == 'light' ? 'black' : 'white';

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    // Implement ERC721 Option function
    // Mint Option from FAKEBAYC
    // function writeOption(uint _strike, uint _expiry, uint _premium, uint _tokenId)
    // Redeem Option
    // Sell Option
    // approveForAll
    // setInterfaceContract
    

  return (
    <div >
    <Button variant='outlined' 
    sx={{ marginLeft: "auto", color: menuColor, borderColor: menuColor}} 
    style={{paddingTop: 2, paddingBottom:2, maxWidth: '100%', maxHeight: '80%', minWidth: '100%', minHeight: '80%'}}
    onClick={handleOpen} > <Menu/> </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <Container maxWidth='md' align='center' style={{  paddingTop: "5px"}}>
                            <Typography variant='h6' align='left' >
                                Menu 
                            </Typography>
                            </Container>

                            <Container maxWidth='md'align='center'>
                            <Grid container  spacing={{ xs: 2, sm:2, md: 2 }} justify='center' alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>
                            <Grid item xs={6}  >
                                    <Button variant="contained" color={switchPC == 'Call' ? 'primary': 'secondary'} style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                       component={Link} to="/app" >App</Button>
                                </Grid>
                                
                                <Grid item xs={6}  >
                                    <Button variant="outlined" color={switchPC == 'Call' ? 'primary': 'secondary'} style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                       component={Link} to="/" >Front page</Button>
                                </Grid>
                                <Grid item xs={6}  >
                                    <Button variant="outlined" color={switchPC == 'Call' ? 'primary': 'secondary'} style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                       component={Link} to="/docs" >Docs</Button>
                                </Grid>  
                                <Grid item xs={6}  >
                                    <Button variant="outlined" color={switchPC == 'Call' ? 'primary': 'secondary'} style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                      component={Link} to="/calc">Calculator</Button>
                                </Grid>     

                                <Grid item xs={6}  >
                                    <ExtraMenu
                                    fakeBaycAddress = {fakeBaycAddress}
                                    erc721OptionAddress = {erc721OptionAddress}>

                                    </ExtraMenu>
                                </Grid>                                
                            </Grid>
                            <Container maxWidth='md'align='center'>
                                <Stack direction="row" spacing={2}>
                                    <IconButton onClick={() => window.open('https://testnets.opensea.io/collection/ztrike-option-muvdry8uxv')}><img src={OSwhite} width={20} /></IconButton>
                                    <IconButton onClick={() => window.open(`https://sepolia.etherscan.io/address/${erc721OptionAddress}`)}><img src={ESlogo} width={20}/></IconButton>
                                    <IconButton  onClick={() => window.open('https://twitter.com/ztrike_io')}>
                                    <Twitter fontSize='medium'/>  
                                    </IconButton>
                                </Stack>
                                </Container>
                            </Container>
        </Box>
      </Modal>
    </div>
  );
}