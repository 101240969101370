import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography, CardMedia } from '@mui/material';
import { Container, Skeleton } from '@mui/material';


import placeholder from './ZvgPattern.svg'; //testing


export default function ModalCardMedia(props) {


if (props.currImage==""){
return(
    <Box sx={{ width: '100%', height:"270px"}}>
      <Container>
      <LinearProgress color="primary"  />
      <Skeleton animation="wave" variant="rectangular"
       height={240} sx={{marginTop:1, borderRadius:1}}/>
    </Container>  
  </Box>
);

} else{
    return (
        <Box sx={{ width: '100%' }}>
                    <CardMedia
                        component="img"
                        height="400"
                        width="1/2"
                        image={props.currImage} //'metadata/testImage.png' //'https://lh3.googleusercontent.com/7h6yA2MwfeVC_k493JXGDbVetx7hlIFEohvflEBSVKH2C-OhEXvzUZdNcaAKWEMJMDZUFaljbHi2LzZg6busnhzi6fExqsgygAuEEA=w600'
                        alt=""
                        sx= {{padding: 0, objectFit: "contain", minHeight:'201'}}
                    />
                      
        </Box>
          );
};
}