import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Container, Skeleton } from '@mui/material';

export default function OptCardMedia(props) {
const height = props.height;

if (props.currOptionImage==""){
    //console.log(props.currentOptionURI);
return(
    <Box sx={{ width: '100%', height: height}}>
        <Container>
            <LinearProgress color="primary"  />
            <Skeleton animation="wave" variant="rectangular"
       height={350} widht='100%' sx={{marginTop:1, borderRadius:2}}/>
        </Container>  
  </Box>
);

} else{
    return (
        <Box sx={{ width: '100%', height: height }}>
            <embed src={props.currOptionImage} height={height} width='100%'/>
        </Box>
          );
};
}