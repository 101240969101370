import React from 'react';
import { Card, CardContent, Stack, ButtonGroup, Button, Typography, Grid } from '@mui/material';
import FormattedPrice from './FormattedPrice';

const OrderCard = ({ theme, order, isBid }) => {
  const cardStyle = {
    backgroundImage: theme.custom.gradient1,
    margin: 2,
    minHeight: '20px',
    border: 0,
    borderColor: isBid ? 'primary.main' : 'secondary.main',
    width: '100%'
  };

  const buttonGroupStyle = {
    marginBottom: 0,
    marginTop: 0,
    width: '45%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  };

  return (
    <Grid item xs={12}>
      <Card style={cardStyle}>
        <CardContent sx={{ 
          padding: '0 !important', 
          paddingBottom: '0 !important',
          paddingTop: '0 !important',
          paddingLeft: isBid ? '0 !important' : '0.25rem !important',
          paddingRight: isBid ? '0.25rem !important' : '0 !important',
          margin: 0, 
          height: '100%' 
        }}>
          <Stack direction="row" sx={{ minHeight: '100%', padding: 0, margin: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
            {isBid && (
              <ButtonGroup orientation="vertical" sx={buttonGroupStyle}>
                <Button 
                  variant="contained" 
                  size="small" 
                  sx={{ fontSize: 10, paddingTop: 0, paddingBottom: 0, flex: 1, color: 'white' }} 
                  color='secondary'
                  disabled={!order}
                >
                  Sell
                </Button>
                <Button 
                  variant="outlined" 
                  size="small" 
                  sx={{ 
                    fontSize: 10, 
                    paddingTop: 0, 
                    paddingBottom: 0, 
                    flex: 1, 
                    color: theme.palette.mode === 'dark' ? 'white' : 'black' 
                  }} 
                  color="secondary"
                >
                  Bid
                </Button>
              </ButtonGroup>
            )}
            
            {order ? (
              <FormattedPrice price={order.price} isBid={isBid} />
            ) : (
              <FormattedPrice price={"-"} isBid={isBid} />
            )}
            
            {!isBid && (
              <ButtonGroup orientation="vertical" sx={buttonGroupStyle}>
                <Button variant="contained" 
                disabled={!order}
                size="small" sx={{ fontSize: 10, paddingTop: 0, paddingBottom: 0, flex: 1, borderColor: 'white' }} color='primary'>
                  Buy
                </Button>
                <Button variant="outlined" size="small" sx={{ fontSize: 10, paddingTop: 0, paddingBottom: 0, flex: 1, color: theme.palette.mode === 'dark' ? 'white' : 'black' }} color="primary">
                  Ask
                </Button>
              </ButtonGroup>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderCard;