import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Stack, Box, Card, CardContent, Button, createTheme, ThemeProvider } from '@mui/material';

import useStyles from './styles';
import { Typography, CssBaseline, Container} from '@mui/material'

import {OptSwipe} from './OptSwipe';
import {MintForm} from './MintForm';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AppBarModule} from './AppBarModule';
import {AppBarFooter} from './AppBarFooter';

import {OrdersView} from './OrdersView';
import { OptionsView } from './OptionsView';
import {OrderViewContract} from './OrderViewContract';

import { useState } from 'react';
import {Ztrike} from './ztrikeLogoConst';
import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';

//import SvgComponent from './optionMetadata/OptionSVG';

import { Link } from "react-router-dom";

import { getOrders, getBlockGas, supabase,getUniqueUnderlyingFromOrders } from './supaBaseFuncs.js';


import Onboard, { ThemingMap } from '@web3-onboard/core'
import { Web3OnboardProvider, init, useConnectWallet } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import { ethers } from 'ethers'



import walletConnectModule from '@web3-onboard/walletconnect';
import phantomModule from '@web3-onboard/phantom'
import coinbaseWalletModule from '@web3-onboard/coinbase'
const coinbaseWalletSdk = coinbaseWalletModule()

const customTheme = {
  '--w3o-background-color': '#232526', 
  '--w3o-foreground-color': '#414345',
  '--w3o-text-color': '#fff',
  '--w3o-border-color': '#fffff',
  '--w3o-action-color': '#f44336',
  '--w3o-border-radius': '12px'
}

const wcInitOptions = {
    /**
     * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
     */
    projectId: '9a2720e8c4ba95bac8830a812a6d68ad',
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    requiredChains: [1],
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    optionalChains: [11155111, 42161, 8453, 10, 137, 56],
    /**
     * Defaults to `appMetadata.explore` that is supplied to the web3-onboard init
     * Strongly recommended to provide atleast one URL as it is required by some wallets (i.e. MetaMask)
     * To connect with WalletConnect
     */
    dappUrl: 'http://ztrike.netlify.app'
  };
  
  // initialize the module with options
  const walletConnect = walletConnectModule(wcInitOptions);
  const phantom = phantomModule();

let web3Onboard;
//const apiKey = '1730eff0-9d50-4382-a3fe-89f0d34a2070'
const injected = injectedModule()
const INFURA_URL = process.env.REACT_APP_INFURA_URL;
const MORALIS_API_KEY = process.env.REACT_APP_MORALIS_API_KEY;

console.log("_______________________");
console.log("STARTING: INFURA --> ", INFURA_URL, typeof(INFURA_URL));
console.log("_______________________");
const rpcUrl = `https://mainnet.infura.io/v3/${INFURA_URL}`
const sepoliaRpcUrl = `https://sepolia.infura.io/v3/${INFURA_URL}`
//const sepoliaRpcUrl = `https://site1.moralis-nodes.com/sepolia/${MORALIS_API_KEY}`;

console.log("->>>", rpcUrl, sepoliaRpcUrl);

const initializeOnboard = async () => {
    const ethereumSepolia = {
      id: 11155111,
      token: 'ETH',
      label: 'Sepolia',
      rpcUrl: sepoliaRpcUrl
    };
const mainnet = {
  id: '0x1',
  token: 'ETH',
  label: 'Ethereum Mainnet',
  rpcUrl: rpcUrl
};

    const chains = [
      ethereumSepolia
    ];
  
    const wallets = [injectedModule(), walletConnect, phantom,  coinbaseWalletSdk];
  
    web3Onboard = Onboard({
      theme: customTheme,
      wallets,
      chains,
      appMetadata: {
        name: 'ztrike.io',
        icon: Ztrike,
        description: 'The NFT option protocol'
      }
    });
  
    init({
      apiKey: '1730eff0-9d50-4382-a3fe-89f0d34a2070', //
      wallets,
      chains,
      appMetadata: {
        name: 'ztrike.io',
        icon: Ztrike,
        description: 'The NFT option protocol'
      }
    });
  };


  const App = () => {
    const [onboardInitialized, setOnboardInitialized] = useState(false);
  
    useEffect(() => {
      initializeOnboard().then(() => setOnboardInitialized(true));
    }, []);
  
    if (!onboardInitialized) {
      return <div>Loading...</div>;
    }
  
    return (
      <Web3OnboardProvider web3Onboard={web3Onboard}>
        <MainContent />
      </Web3OnboardProvider>
    );
  };
  

const MainContent = () => {


    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [ethersProvider, setEthersProvider] = useState(null);
  
    useEffect(() => {
      if (wallet) {
        const provider = new ethers.BrowserProvider(wallet.provider, 'any');
        setEthersProvider(provider);
      }
    }, [wallet]);


    const [mode, setMode] = useState("light");

    const theme = React.useMemo(
      () =>
        createTheme({
          palette: {
            mode,
            ...(mode === 'light'
              ? {
                  // Light mode
                  primary: {
                    light: '#757ce8',
                    main: '#264a39',
                    dark: '#607d8b',
                    contrastText: '#fff',
                  },
                  secondary: {
                    light: '#ff7961',
                    main: '#f44336',
                    dark: '#e64a19',
                    contrastText: '#000',
                  },
                  background: {
                    default: '#fcfcfc',
                    paper: '#dbdbdb',
                  },
                }
              : {
                  // Dark mode
                  primary: {
                    light: '#757ce8',
                    main: '#264a39',
                    dark: '#607d8b',
                    contrastText: '#fff',
                  },
                  secondary: {
                    light: '#ff7961',
                    main: '#f44336',
                    dark: '#e64a19',
                    contrastText: '#000',
                  },
                  background: {
                    default: '#414345',
                    paper: '#232526',
                  },
                }),
          },
          typography: {
            fontFamily: 'Inter',
          },
          shape: {
            borderRadius: 12,
          },
          custom: {
            gradient1: mode === 'dark'
              ? "radial-gradient(circle at top, #414345, #232526)"
              : "radial-gradient(circle at top, #fcfcfc, #dbdbdb)",
          },
        }),
      [mode]
    );

    
//const gradient1 = "radial-gradient(circle at top, #414345, #232526)";
const gradient1 = "radial-gradient(circle at top, #fcfcfc, #dbdbdb)";


      
const [userAccount, setUserAccount] = useState(''); //chg
const [amount, setAmount] = useState();
const [strike, setStrike]=useState();
const [fakeBaycAddress, setBAYC] = useState(NFTAddress);
const [erc721OptionAddress, setOption] = useState(ZtrikeAddress); // goerli: 0x1BbF422577Cd203C1F71Be477cC8d552854F505a
const [isTransferDisabled, setIsTransferDisabled] = useState(false);
const [currentOption, setCurrentOption] = useState(0);
const [switchPC, setPC] = React.useState('Call');
const [bb, setBlockNo] = useState(0);
const [currGas, setCurrGas] = useState(0);

const fetchBlock = async () => {
  let [bb, fGas] = await getBlockGas();
  //console.log("GETTING BLOCKNO...", bb, fGas);
  setCurrGas(fGas);
  setBlockNo(bb);
}

useEffect(() => {
  console.log("supabase: SUBSCRIBING TO CHANNEL (block)");
  const channels = supabase.channel('main-app-block-channel')
  .on(
  'postgres_changes',
  { event: '*', schema: 'public', table: 'blockInfo' },
  (payload) => {
      console.log('supabase: blockInfo Change received!', payload);
      fetchBlock();
  }
  )
  .subscribe()

  // add return right here!
  return () => channels.unsubscribe();
}, []);

useEffect(() => {
  console.log("fetching blocks at startup");
      fetchBlock();

}, []);


React.useEffect(
  ()=>{
          async function updateOnce(){
                  let [bb, fGas] = await getBlockGas();
                  
                  setBlockNo(bb);
                  setCurrGas(fGas);
          }
          updateOnce();
          
  }, []
); // runs once



React.useEffect(() => { const interval = setInterval(
  () => {
          const fetchBlock = async () => {
                  let [bb, fGas] = await getBlockGas();
                  setCurrGas(fGas);
                  setBlockNo(bb);
          }

          fetchBlock();
          
  }, 100000);
  return () => clearInterval(interval); }, []);


const [tabBar, setTabBar] = React.useState([['Write',   true], 
                                            ['Options', false],
                                            ['Trade',   false], 
                                            ['Orders',  false]]);


function toggleTabBar(i){
    console.log("tabbar", i);
    let cc = [['Write',   false], 
              ['Options', false],
              ['Trade',   false], 
              ['Orders',  false]];

    cc[i] = [tabBar[i][0], true];
    console.log("tabbar", cc)
    setTabBar(cc);
}

function handleDisableTransfer() {
    console.log("Toggled transfer");
    setIsTransferDisabled(!isTransferDisabled);
};



const classes = useStyles(theme);




    return (
<Web3OnboardProvider web3Onboard={web3Onboard}>
    <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ background: theme.custom.gradient1}} >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ztrike | App</title>
                <link rel="canonical" href="http://ztrike.io/app" />
                <style>{'body { background-color: black; }'}</style>
            </Helmet>


                <AppBarModule
                                            userAccount={userAccount}
                                            setUserAccount={setUserAccount}
                                            switchPC={switchPC}
                                            erc721OptionAddress = {erc721OptionAddress}
                                            fakeBaycAddress = {fakeBaycAddress}
                                            mode={mode}
                                            setMode={setMode}
                
                />
                <main >
                <div className={classes.container2} style={{maxWidth:'100%', minHeight:'100vh'}}>

                        <br/><br/>                        
                        
                        {false ? 
                            <Container maxWidth='md'>
                            <Typography variant='h6' >
                            Write option contract
                            </Typography>
                            </Container>
                            : ''}
                        
                        <Container maxWidth='md' align='center' style={{  paddingTop: 0, paddingBottom:0}}
                            sx={{border:0, borderColor:'white'}}>
                            <Box style={{margin:0, padding:0, marginBottom:2}} 
                                sx={{ backgroundColor: 'transparent', boxShadow: 'none', 
                                border:0,  borderColor: 'red', borderRadius:1, p: 1,
                                overflow: 'hidden'}}>
                            <Stack direction="row" sx={{display: 'flex',
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'space-between',
                                                        gap: 1,
                                                        border: 0,
                                                        borderColor: 'white',
                                                        borderRadius: 0,
                                                        p: 1,
                                                        margin:0, padding:0
                                                    }}>
                                {
                                    tabBar.map( (e, index) => {
                                        return (


                                                <Button key={index} variant='outline' onClick={()=>toggleTabBar(index)}
                                                style={{padding:5, margin:0, paddingLeft:1}}
                                                sx={{
                                                    flexGrow: 1,
                                                    flexBasis: { xs: '40%', sm: '22%' },
                                                    maxWidth: { xs: '23%', sm: '23%' },
                                                    minWidth: 'auto',
                                                    p: 1,
                                                    background: e[1] ?  theme.palette.background.paper : 'transparent',
                                                    border: 0,
                                                    borderRadius: 1,
                                                    '&:hover': {
                                                      background: e[1] ? theme.palette.background.paper : 'primary.dark',
                                                    },
                                                  }}
                                                >
                                                        <Typography noWrap variant='body2' style={{padding:0, margin:0, fontSize:10}}>
                                                            {e[0]}
                                                        </Typography>
                                                </Button>
                                            );
                                    })
                                }
                                    
                                
                        </Stack>
                            
                   
                        </Box>
                        </Container>

                        { tabBar[0][1] ?
                        <Container maxWidth='md' align='center' style={{  paddingTop: 0, paddingBottom:'-200px'}}>
                            <MintForm  fakeBaycAddress = {fakeBaycAddress} 
                            setBAYC= {setBAYC}
                            erc721OptionAddress = {erc721OptionAddress} 
                            amount = {amount} 
                            userAccount={userAccount}
                            setUserAccount={setUserAccount}
                            setAmount={setAmount}
                            setOption={setOption} 
                            isTransferDisabled={isTransferDisabled} 
                            toggleDisabled={handleDisableTransfer}
                            strike = {strike}
                            setStrike={setStrike}
                            currentOption={currentOption}
                            setCurrentOption={setCurrentOption}
                            switchPC={switchPC}
                            setPC={setPC}
                            bb={bb}
                            setBlockNo={setBlockNo}
                            />
                        </Container>
                            : '' }
                            
                        
                        
                        { tabBar[1][1] ? 

                        <Container maxWidth='md' align='center' style={{  paddingTop: 0, paddingBottom:'-200px'}}>
                            <OptionsView
                            userAccount = {userAccount}
                            switchPC = {switchPC}
                            toggleTabBar={toggleTabBar}
                            theme={theme}
                            />
                            <OptSwipe
                            fakeBaycAddress = {fakeBaycAddress} 
                            erc721OptionAddress = {erc721OptionAddress} 
                            amount = {amount} 
                            userAccount={userAccount}
                            setUserAccount={setUserAccount}
                            setAmount={setAmount}
                            setBAYC={setBAYC}
                            setOption={setOption} 
                            strike = {strike}
                            setStrike={setStrike}
                            currentOption={currentOption}
                            setCurrentOption={setCurrentOption}
                            switchPC={switchPC}
                            setPC={setPC}
                            bb={bb}
                            />
                        </Container>
                        : '' }
                
                    
                { tabBar[2][1] ? 
                        
                    
                        <Container maxWidth='md' align='center' style={{  paddingTop: 0}}>
                            <OrderViewContract
                            userAccount = {userAccount}
                            switchPC = {switchPC}
                            theme={theme}
                            curr={bb}
                            />
                        </Container>
                        : '' }

                        { tabBar[3][1] ? 

                        <Container maxWidth='md' align='center' style={{  paddingTop: "0px"}}>
                            <OrdersView
                            userAccount = {userAccount}
                            switchPC = {switchPC}
                            theme={theme}
                            />
                        </Container>
                        : '' }

                       

                            
                    
                        
                </div>
              
                </main>
            {true ? 
            <AppBarFooter 
            bb={bb}
            gg={currGas}
            setBlockNo={setBlockNo}
            erc721OptionAddress={erc721OptionAddress}
            mode={mode}
            />
            :''}

            </div>
 
        </ThemeProvider>
</Web3OnboardProvider>
    );
}

export default App;