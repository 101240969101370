import React from 'react';
import { Typography, Stack, Box } from '@mui/material';
import { ethers } from 'ethers';

const FormattedPrice = ({ price, isBid }) => {
    const isPlaceholder = price === "-";
    
    let priceDisplay;
    if (!isPlaceholder) {
        const formattedPrice = ethers.formatEther(String(price));
        const [integerPart, decimalPart = ''] = formattedPrice.split('.');
        const paddedDecimal = (decimalPart + '0000').slice(0, 4);
        const firstTwoDecimals = paddedDecimal.slice(0, 2);
        const lastTwoDecimals = paddedDecimal.slice(2, 3);
        priceDisplay = (
            <Stack direction="row" alignItems="baseline">
                <Typography component="span" fontSize={10}>
                    {integerPart}.
                </Typography>
                <Typography component="span" fontSize={30}>
                    {firstTwoDecimals}
                </Typography>
                <Typography component="span" fontSize={10}>
                    {lastTwoDecimals}
                </Typography>
            </Stack>
        );
    } else {
        priceDisplay = (
            <Typography fontSize={30} textAlign="center">-</Typography>
        );
    }

    const priceContainer = (
        <Box width="50px" display="flex" justifyContent="flex-end" alignItems="center">
            {priceDisplay}
        </Box>
    );

    if (isBid) {
        return (
            <Stack direction="row" sx={{marginLeft:'auto'}}>
                <Typography size="small" style={{fontSize:10, paddingLeft:3}}>
                    Bid
                </Typography>
                <div style={{paddingTop:10}}>
                    <img src="/static/media/ethlogo.c729bd46b192b8ea53dcc0d30b14ad9e.svg" 
                         height="20" 
                         width="20"/>
                </div>
                {priceContainer}
            </Stack>
        );
    } else {
        return (
            <Stack direction="row">
                <div style={{paddingTop:10}}>
                    <img src="/static/media/ethlogo.c729bd46b192b8ea53dcc0d30b14ad9e.svg" 
                         height="20" 
                         width="20"/>
                </div>
                {priceContainer}
                <Typography size="small" style={{fontSize:10, paddingLeft:3}}>
                    Ask
                </Typography>
            </Stack>
        );
    }
};

export default FormattedPrice;