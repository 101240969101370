import * as React from "react";
import Modal from "@mui/material/Modal";
import OptSwipeable from "./OptSwipeable";
import { ethers } from "ethers";
import BAYC from "./artifacts/contracts/FakeBAYC.sol/BAYC.json";
import Option from "./artifacts/contracts/ERC721Option.sol/ERC721Option.json";
import {getTransactions, getWrittenOptions} from './Etherscan';
import { Box, Button} from "@mui/material";

import { initializeWallet, redeemOption, regretOption, retrieveOption } from "./Etherfuncs";
//ethers.Logger.setLogLevel("off"); //debug, default, warn, error, info

import { useConnectWallet } from '@web3-onboard/react'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  outline: "none",
};

export function OptSwipe(props) {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  
  // prepare Etherfuncs.js
  React.useEffect(() => {
    if (wallet) {
      initializeWallet(wallet);
    }
  }, [wallet]);

  const erc721OptionAddress = props.erc721OptionAddress;
  const userAccount = props.userAccount;
  const setUserAccount = props.setUserAccount;
  const switchPC = props.switchPC;
  const bb = props.bb;
    
  const [open, setOpen] = React.useState(false);
  const [listOfOptions, setListOfOptions] = React.useState([{id: ''}])
  const [currentOptionURI, setCurrentOptionURI] = React.useState("");
  const [currOptionImage, setCurrOptionImage] = React.useState("");
  const [activeStrike, setActiveStrike] = React.useState('');
  const [activePC, setActivePC] = React.useState('Call');
  const [activeUnderlyingAddress, setActiveUnderlyingAddress] = React.useState('0x....');
  const [activeUnderlyingID, setActiveUnderlyingID] = React.useState(0);
  const [activeExpiry, setActiveExpiry] = React.useState(7243);
  const [activeWriter, setActiveWriter] = React.useState('0x');
  const [activeOwner, setActiveOwner] = React.useState('0x');
  const [showHide, setShowHide] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState('');
  
  function handleOpen(){
    isOptionOwner();
    setOpen(true);
  }

  function handleClose(){
    setOpen(false);
    setShowHide(false);
  }
  
  async function requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  async function isOptionOwner() {
    var newlist = [];
    setListOfOptions([{id: ''}])
    if (typeof window.ethereum !== 'undefined') {
      const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setUserAccount(account); 
      newlist = await getTransactions(account, erc721OptionAddress);
      const writeList = await getWrittenOptions(account, erc721OptionAddress);
      console.log('WRITTEN OPTIONS');
      console.log(writeList);
      console.log(newlist);

      console.log(newlist.includes(newlist[0]));
      for (var i = 0; i < writeList.length; i++){
        
        if (newlist.filter(e => e.id === writeList[i].id).length > 0){
          console.log(`newlist includes ${writeList[i].id}`)
        } else {
          console.log("pushed")
          newlist.push(writeList[i])
        }
      }
    }
    setListOfOptions(newlist);
    tokenURI(newlist[0].id);
  };


    async function tokenURI(activeStepNFTid) {
      // TODO: replace with supabase call since tokenURI doesnt change and is already stored
      //setActiveStrike('');
      //setActivePC('');
      //const provider = new ethers.providers.Web3Provider(window.ethereum);
      console.log("--using Onboard provider in TokenURI")
      if (wallet) {
        //let account = wallet.accounts[0].address;
        const provider = new ethers.BrowserProvider(wallet.provider, 'any')
        //const signer = await provider.getSigner()
        //... reset of transaction
        const contract = new ethers.Contract(erc721OptionAddress, Option.abi, provider);
        const tokenidURI = await contract.tokenURI(activeStepNFTid);
        setCurrentOptionURI(tokenidURI);
  
        // Set current owner
        const transaction = await contract.ownerOf(activeStepNFTid);
        console.log(transaction);
        setActiveOwner(transaction);
    }
    }

    async function getImageFromMetadata(currentOptionURI) {
      //setCurrOptionImage('')
      setActiveStrike('');
      setActivePC('');
      setActiveUnderlyingAddress('');
      setActiveWriter('');
      setActiveUnderlyingID('');
      //console.log(`currentToken: ${selectedOption}`)
      const response = await fetch(
        currentOptionURI //"http://localhost:3000/" +
      ).then((data) => data.clone().json());
      console.log(response);

      setActiveUnderlyingAddress(response["attributes"][0]['value']);
      setActiveUnderlyingID(response["attributes"][1]['value']);
      setActiveStrike(response["attributes"][2]['value']);
      setActiveExpiry(response["attributes"][3]['value']);
      setActivePC(response["attributes"][4]['value']);
      setActiveWriter(response["attributes"][5]['value']);
      try{
          // Specification #1
          setCurrOptionImage(response["properties"]["image"]["description"]);
      }catch{
          // Specification #2
          setCurrOptionImage(response["image"]);
      }
    }

  return (
    <div>
      <Button
        variant="contained"
        style={{
          paddingTop: 10,
          maxWidth: "100%",
          maxHeight: "100%",
          minWidth: "100%",
          minHeight: "100%",
          marginTop: 5,
        }}
        color={switchPC == 'Call' ? 'primary': 'secondary'}
        onClick={handleOpen}
        disabled={!ethers.isAddress(userAccount)}
      >
        {ethers.isAddress(userAccount) ? 'View options' : 'Connect wallet'}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >


        <Box sx={style}>
          <OptSwipeable
            listOfOptions={listOfOptions}
            redeemOption={redeemOption}
            tokenURI={tokenURI}
            currentOptionURI={currentOptionURI}
            currOptionImage={currOptionImage}
            setCurrOptionImage={setCurrOptionImage}
            getImageFromMetadata={getImageFromMetadata}
            open={open}
            activeStrike={activeStrike}
            activePC={activePC}
            activeUnderlyingAddress = {activeUnderlyingAddress}
            activeUnderlyingID = {activeUnderlyingID}
            activeExpiry = {activeExpiry}
            switchPC = {switchPC}
            bb = {bb}
            activeWriter={activeWriter}
            userAccount={userAccount}
            activeOwner={activeOwner}
            retrieveOption={retrieveOption}
            regretOption={regretOption}
            showHide={showHide}
            loadingText={loadingText}
            erc721OptionAddress={erc721OptionAddress}
          />
        </Box>
      </Modal>
    </div>
  );
}
