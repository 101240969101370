
import * as React from 'react';
import {Box, Skeleton, Link, IconButton,FormGroup, FormControlLabel, Checkbox, Card, CardContent, CardActions, Stack, Divider, Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Typography, ButtonGroup, CardMedia} from '@mui/material';
import {SelectNFTmodal} from './ModalSwipe';
import { ethers } from 'ethers'

import {Logo} from './ethlogo.js';
import InputAdornment from '@mui/material/InputAdornment';
import IOSSwitch from './IOSwitch';
import {ContractModal} from './ContractModal';
import UploadFile from './IPFSupload';
import LinearProgress from './LinearProgress';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useState, useEffect } from 'react';
import OSwhite from './OSwhite.svg' ;
import ModalCreateOrder from './ModalCreateOrder';
import { useTheme } from '@material-ui/core/styles';

//import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json'
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json'
import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';
import {initializeWallet, redeemOption, regretOption, retrieveOption } from "./Etherfuncs";
import {ethCreateOfferInitializeWallet, createOrder, acceptOrder, getOrder, cancelOrder} from './ethCreateOffer.js';
import { getOrders, getBlockGas, supabase, getOptions, getOptionImage, getOwners} from './supaBaseFuncs.js';
import { getWrittenOptions, getTransactions } from './Etherscan.js';
import OptCardMedia from './OptCardMedia.js';

import { useConnectWallet } from '@web3-onboard/react'

const INFURA_URL = process.env.REACT_APP_INFURA_URL;

//(BigInt.prototype).toJSON = function () {
//    return Number(this.toString());
//  }; // monkey path

export function OptionsView(props) {
  const theme = props.theme;

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

  React.useEffect(() => {
    if (wallet) {
      initializeWallet(wallet);
      ethCreateOfferInitializeWallet(wallet);
    }
  }, [wallet]);


      const switchPC = props.switchPC;
      const userAccount = props.userAccount;
      const toggleTabBar = props.toggleTabBar;

      const [MyOrders, setMyOrders] = useState([]);
      const [curr, setCurr] = React.useState(6637107);
      const [currGas, setCurrGas] = React.useState('11');
      const [filterOn, setFilterOn] = React.useState(false);

      const [filterContract, setFilterContract] = useState('');
      const [filterCreator, setFilterCreator] = useState('');
      const [showExpired, setShowExpired] = useState(false);
      const [showCancelled, setShowCancelled] = useState(false);
      const [filteredOptions, setFilteredOptions] = useState([]);


      const [listOfOptions, setListOfOptions] = React.useState([])
      


 // Add this new function to sort the options
 const sortOptions = (options, userAccount) => {
  return options.sort((a, b) => {
    const aIsOwner = a.ownerOf.toLowerCase() === userAccount.toLowerCase();
    const bIsOwner = b.ownerOf.toLowerCase() === userAccount.toLowerCase();
    
    if (aIsOwner && !bIsOwner) return -1;
    if (!aIsOwner && bIsOwner) return 1;
    
    return a.optionId - b.optionId;
  });
};




    // filter options
    useEffect(() => {
      console.log("StateChanged", listOfOptions[0], curr);

      if (listOfOptions.length > 0){
          const f = listOfOptions.filter(option => {
              const contractMatch = !filterContract || option.underlyingContract.toLowerCase() === filterContract.toLowerCase();
              const creatorMatch = !filterCreator || option.optWriter.toLowerCase() === filterCreator.toLowerCase();
              const expiryMatch = showExpired || (Number(option.expiry) - curr) >= 1;
              const cancelledMatch = showCancelled || (!option.isCancelled && (option.ownerOf.toLowerCase() != '0x0000000000000000000000000000000000000000'));
              return contractMatch && creatorMatch && expiryMatch && cancelledMatch;
            });
          console.log(f);
            // Sort the filtered options if userAccount is defined
      const sortedOptions = userAccount ? sortOptions(f, userAccount) : f;
      
      setFilteredOptions(sortedOptions);
      }

    }, [listOfOptions, showExpired, showCancelled, filterContract, filterCreator, curr, userAccount]);




      async function getOptionsFromSupabase(){
            setListOfOptions([]);
            var options = await getOptions();
            const owners = await getOwners();
            console.log("Owners:", owners);
            console.log('Load Options:',options);
            console.log(options.length);
            
            for (var i=0; i < options.length; i++){
                let thisOp = owners.findIndex(({ optionId }) => optionId === options[i].optionId);
                let thisOwner = '0x0000000000000000000000000000000000000000';
                try{
                  thisOwner = owners[thisOp]['owner'];
                } catch{
                  console.log(thisOp, owners[thisOp], "Failed to find owner");
                }
                
                 //console.log(i)
                 if (options.tokenURI != 'none'){
                  let optImg = await getOptionImage(options[i].optionId);
                  //console.log(optImg);
                  let ownerOf = '...';//await ZtrikeContractProvider.ownerOf(options[i].optionId);
                  options[i] = { ...options[i], 
                               "publicURL": optImg['publicUrl'],
                               'ownerOf': thisOwner};
                 }
                }
            console.log("options >>>>", options);
            setListOfOptions(options);
      }

      React.useEffect( () => {
                
        getOptionsFromSupabase();
        
      }, []);


      useEffect(() => {
        console.log("supabase: SUBSCRIBING TO OPTIONS CHANNEL");
        const channels = supabase.channel('optionsView-options-channel')
        .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'options' },
        (payload) => {
            console.log('supabase: Change received!', payload);
            getOptionsFromSupabase();
        }
        )
        .subscribe()
      
        // add return right here!
        return () => channels.unsubscribe();
      }, []);


      useEffect(() => {
        console.log("supabase: SUBSCRIBING TO OWNERS CHANNEL");
        const channels = supabase.channel('optionsView-owbers-channel')
        .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'owners' },
        (payload) => {
            console.log('supabase: owners: Change received!', payload);
            getOptionsFromSupabase();
        }
        )
        .subscribe()
      
        // add return right here!
        return () => channels.unsubscribe();
      }, []);

      const fetchBlock = async () => {
        let [bb, fGas] = await getBlockGas();
        console.log("GETTING BLOCKNO...")
        setCurrGas(fGas);
        setCurr(bb);
    }
    
    function toggleFilter(){
        setFilterOn(!filterOn);
    }

    useEffect(() => {
      console.log("fetching blocks at startup");
          fetchBlock();

    }, []);

    return(
    <Card sx={{minHeight:'500px', maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, minWidth: {sm:'97%', sm:'90%', lg:'50%'},
     paddingLeft:1, paddingRight:0, paddingTop:1}}>
      <CardContent sx={{paddingLeft:0, paddingRight:0,  marginLeft:1, marginRight:0}}>    
          
          <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='top' 
          alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>              
              <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <Typography variant="h6" align='left' style={{paddingBottom:0, paddingTop:2}}>
                    Options
                  </Typography>
                  { true ? '' : // hides the button if true
                  <Button variant='contained' color={switchPC == 'Call' ? 'primary': 'secondary'} 
                  style={{maxWidth: '100%', maxHeight: '50%', minWidth: '100%', minHeight: '50%'}}
                  onClick={() => console.log("Not implemented")}
                  disabled={ !ethers.isAddress(userAccount)}>
                    {( ethers.isAddress(userAccount)) ? 'Update' : 'Connect wallet'}
                  </Button> // end of buttonhiding 
                  } 
                    <Stack 
                    direction="row" 
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        paddingTop: 2,
                        paddingRight:1,
                        alignContent:'top'

                    }}
                    >
                    <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6.5, paddingRight:1 }}>
                        <IconButton onClick={() => toggleFilter()}>
                            {filterOn ?  <FilterListOffIcon/> : <FilterListIcon/>}
                        </IconButton>
                    </Box>
                   

                    <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6 }}>
                    <Button variant="outlind" size="small" 
                        onClick={() => toggleTabBar(0)} color = {props.switchPC == 'Call' ? 'primary': 'secondary'}
                        sx={{border:1, borderColor:'primary.main', width:'100%'}}>
                          <Typography sx={{fontSize:12, color:'primary.main'}}>
                          Write new option
                          </Typography>
                        
                        </Button>
                    </Box>
                    </Stack>
                
                    {filterOn ? 
                    <Divider textAlign="left" sx={{paddingBottom:2}}>Filter Orders</Divider>
                    : ''}
                {filterOn ? 
                    <TextField id="outlined-basic" 
                    label="Underlying Contract" fullWidth
                    variant="outlined" 
                    size="small"
                    value={filterContract}
                    onChange={(e) => setFilterContract(e.target.value)}
                    sx={{paddingBottom:2, paddingRight:1}}/>
                    : ''}
                
                {filterOn ? 
                     <TextField id="outlined-basic" 
                     label="Option Writer" fullWidth
                     variant="outlined" 
                     size="small"
                     value={filterCreator}
                    onChange={(e) => setFilterCreator(e.target.value)}
                     sx={{paddingBottom:-1, paddingRight:1}}/>
                    : ''}  
                {filterOn ? 
                     <TextField id="outlined-basic" 
                     label="Option Owner" fullWidth
                     variant="outlined" 
                     size="small"
                     value={filterCreator}
                    onChange={(e) => setFilterCreator(e.target.value)}
                     sx={{paddingBottom:-1, paddingRight:1}}/>
                    : ''}  

               
                {filterOn ? 
                      <FormGroup>
                      <FormControlLabel 
                        control={
                          <Checkbox 
                            checked={showExpired} 
                            onChange={(e) => setShowExpired(e.target.checked)}
                          />
                        } 
                        label="Show expired options" 
                      />
                       <FormControlLabel 
                        control={
                          <Checkbox 
                            checked={showCancelled} 
                            onChange={(e) => setShowCancelled(e.target.checked)}
                          />
                        } 
                        label="Show cancelled/redeemed options" 
                      />
                    </FormGroup>
                    : ''}
                {filterOn ? 
                     <Divider sx={{paddingTop:2}}/>
                    : ''}

                
              </Grid>

              <Grid container item xs={12} wrap='nowrap' style={{ paddingTop: 0, 
                                                maxHeight:'1000px',
                                                overflowY:'hidden',
                                                overflowX: 'atuo',
                                                display: "flex",
                                                flexDirection:"row",
                                                maxWidth: '100%',
                                                scrollbarColor: '#282929 #6f7070',
                                                scrollbarWidth: 'thin',
                                                marginBottom: 0}}>
                {  (filteredOptions.length == 0) ? 
                [1,2,3,4,5,6].map( (e, index) => { 
                  //console.log("ee", e);
                  return (
                <Grid item xs={12} sm={10} md={6} 
                style={{ padding: 3, paddingTop:0, marginTop:0, marginBottom:10,
                 minWidth:'190px', maxWidth:'190px', minHeight:'400px'}} 
                 key={`skeleton-${index}`}>
                     <Card style={{backgroundImage: theme.custom.gradient1, 
                        padding:2, height:'100%'}}>
                            <Box style={{marginTop:0, paddingTop:0}}>
                          
                          <Skeleton variant="rounded" width={210} height={250} />
                          
                          <Skeleton variant="rectangular" width={210} height={60} />
                          
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            </Box>
                            </Card>
                        </Grid>
                  )})
                :
                filteredOptions.map( (e, index) => { 
                        //console.log("ee", e);
                        return (
                            <Grid item  key={`filteredOptions-${index}-${e.optionId}`} xs={12} sm={10} md={6} 
                            style={{ padding: 3, paddingTop:0, marginTop:0, marginBottom:10,
                             minWidth:'190px', maxWidth:'190px', minHeight:'200px'}} 
                             >
                                 <Card style={{backgroundImage: theme.custom.gradient1, 
                                    padding:2, height:'100%'}}>
                                        <Box style={{marginTop:0, paddingTop:0}}>
                                        <OptCardMedia currOptionImage={e.publicURL} height='310px'/>

                                        </Box>
                                      <Stack direction="column" sx={{justifyItems:'left', alignItems:'flex-start'}}>

                                      
                                        <Stack direction="row" spacing={1} align='bottom'>
                                            <Typography gutterBottom variant="h6" component="div" sx={{paddingTop: '8px'}}>
                                            Option # {String(e.optionId)}
                                            </Typography>
                                            <IconButton onClick={() => window.open(`https://testnets.opensea.io/assets/sepolia/${ZtrikeAddress}/${e.optionId}`)}><img src={OSwhite} width={20} /></IconButton>
                                        </Stack>


                                        <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                          {e.isCall ? 'Call' : 'Put'} 
                                          <Link color={e.isCall ? 'primary': 'secondary'}  target="_blank" href={`https://sepolia.etherscan.io/address/${ZtrikeAddress}`}>
                                            {e.contractAddress.substring(0,5) + '...' + e.contractAddress.substring(38,50)} </Link> @ {ethers.formatEther(String(e.strike))}
                                        </Typography>
                                        
                                        <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                        {e.isCall ? `Underlying ID: ${e.underlyingId}` : 'Any NFT from collection'}
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                          Expiry:  <Link color={e.isCall ? 'primary': 'secondary'}  target="_blank" href={`https://sepolia.etherscan.io/block/${e.expiry}`}>  {e.expiry + '  '} </Link> {(Number(e.expiry) < curr ? '(Expired)' : '(Active)')}
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                          Owner: <Link color={e.isCall ? 'primary': 'secondary'} target="_blank" href={`https://sepolia.etherscan.io/address/${e.ownerOf}`}> {e.ownerOf.toLowerCase().substring(0,5) + '...' + e.ownerOf.toLowerCase().substring(38,50)} </Link>  {(e.ownerOf.toLowerCase() == userAccount ? '(you)' : '')}
                                        </Typography>

                                        <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                          Writer: <Link color={ e.isCall ? 'primary': 'secondary'} target="_blank" href={`https://sepolia.etherscan.io/address/${e.optWriter}`}>  {e.optWriter.substring(0,5) + '...' + e.optWriter.substring(38,50)} </Link> {(e.optWriter == userAccount ? '(you)' : '')}
                                        </Typography>
                                        
                                        </Stack>   
                                        <CardActions>
                                        <ButtonGroup style={{margin:0, minWidth:'100%', display:'flex'}}>
                                          <Button size="small" 
                                            color = 'primary'
                                            variant='outlined'
                                            style={{padding:1}}
                                            sx={{flexGrow:1}}
                                            disabled={e.optWriter == e.ownerOf | e.ownerOf != userAccount}
                                            onClick={()=> redeemOption(e.optionId, e.isCall, e.underlyingId, e.strike, e.contractAddress) }>
                                            
                                            <Typography style={{fontSize:11, fontWeight:500}}>
                                            Redeem
                                            </Typography>
                                              
                                              
                                            </Button>
                                            
                                            <Button size="small"
                                            color = 'primary'
                                            variant='outlined'
                                            style={{padding:1}}
                                            sx={{flexGrow:1}}
                                            disabled={e.expiry > curr}
                                            onClick={()=> retrieveOption(e.optionId, e.isCall)  }
                                            >
                                              <Typography style={{fontSize:11, fontWeight:500}}>
                                            Retrieve
                                            </Typography>
                                            </Button>
                                            
                                            <Button size="small"
                                            color = 'primary'
                                            variant='outlined'
                                            style={{padding:1}}
                                            sx={{flexGrow:1}}
                                            disabled={!(String(e.optWriter).toLowerCase() === String(e.ownerOf).toLowerCase()) | !(String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase())}
                                            onClick={()=> regretOption(e.optionId) }
                                            >
                                            <Typography style={{fontSize:11, fontWeight:500}}>
                                            Regret
                                            </Typography>
                                            </Button>
                                        </ButtonGroup>
                                      </CardActions>
                                      <Box sx={{width:'100%', display:'flex', justifyContent:'center', maxHeight:'30px'}}>

                                        <ModalCreateOrder
                                          key={`modal-${e.optionId}-${userAccount}`}
                                          buttonText={ (String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase()) ? `Sell` : 'Bid'}
                                          buttonColor={ !(String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase()) ? 'primary.main' : 'secondary.main'}
                                          activeStrike={ethers.formatEther(String(e.strike))}
                                          activeExpiry={e.expiry}
                                          activePC={e.isCall ? 'call':'put'}
                                          bb={curr}
                                          switchPC = {'Call'}
                                          activeUnderlyingID = {e.underlyingId}
                                          optionID = {e.optionId}
                                          justifyContent = "center"
                                          underlyingContract={e.contractAddress}
                                          disabled = {(e.expiry < curr)}
                                          orderType = { (String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase()) ? 'Offer' : 'Bid'}
                                          specificBid = {true}
                                          />
                                      </Box>
                                      


                                 </Card>
                            </Grid>
                    ) })
                }
              </Grid>



          </Grid>
          
      </CardContent>
</Card>)};














