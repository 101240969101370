import * as React from 'react';
import { Typography, AppBar, Grid, Toolbar, Button, Stack, Box } from '@mui/material';
import { MainMenu } from './MainMenu';
import { ethers } from 'ethers';
import Ztrike from './Ztrike.js';

import { useConnectWallet } from '@web3-onboard/react'
import { initializeWallet, getBalance } from './Etherfuncs.js';
import DarkModeSwitch
 from './darkModeSwitch.js';
export function AppBarModule(props) {



    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

    React.useEffect(() => {
        if (wallet) {
        initializeWallet(wallet);
        }
    }, [wallet]);

    const [currentBalance, setCurrentBalance] = React.useState('-');
    const { userAccount, setUserAccount, switchPC, erc721OptionAddress, fakeBaycAddress, mode, setMode } = props;
    async function updateBalance() {
        if (wallet && wallet.provider) {
            const provider = new ethers.BrowserProvider(wallet.provider);
            const signer = await provider.getSigner();
            const address = await signer.getAddress();
            const balance = await provider.getBalance(address);
            setCurrentBalance(Number(ethers.formatEther(balance)).toFixed(2));
        }
    }

    React.useEffect(() => {
        if (wallet) {
            const walletAddress = wallet.accounts[0].address;
            setUserAccount(walletAddress);
            updateBalance();
        } else {
            setUserAccount('');
            setCurrentBalance('-');
        }
    }, [wallet, setUserAccount]);

    const handleConnect = async () => {
        if (wallet) {
            await disconnect(wallet);
        } else {
            await connect();
        }
    };


    function handleMode(){
        if (mode=="light"){
            setMode("dark");
        } else {
            setMode("light");
        }
    }

    return (
        <AppBar position='fixed' color="transparent" elevation={0} enableColorOnDark>
            <Toolbar sx={{ justifyContent: "space-between", paddingTop: 1 }}>
                <Ztrike width={150} 
                logocolor={switchPC == 'Call' ? "#264a39" : '#b32d2d'}
                mode={mode} />

                <div>
                    <Grid container justify="flex-end">
                        <Stack direction="column" spacing={1}>
                            <Button
                                variant='contained'
                                color={switchPC == 'Call' ? 'primary' : 'secondary'}
                                sx={{ marginLeft: "auto", marginRight: '5px', fontSize: 10 }}
                                onClick={handleConnect}
                                disabled={connecting}
                            >
                                {connecting ? 'Connecting...' : wallet ? (
                                    <Stack direction="row" spacing={0} style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0 }}>
                                        <Typography size="small" style={{ paddingTop: 0, paddingRight: 4, margin: 0, fontSize: 10 }}>
                                            {wallet.accounts[0].address.substring(0, 5) + '...' + wallet.accounts[0].address.substring(38, 50)}
                                        </Typography>
                                        <div style={{ paddingTop: 0 }}>
                                            <img src="/static/media/ethlogo.c729bd46b192b8ea53dcc0d30b14ad9e.svg" height="10" width="10" alt="ETH logo" />
                                        </div>
                                        <Typography size="small" style={{ paddingTop: 0, paddingLeft: 0, margin: 0, fontSize: 10 }}>
                                            {currentBalance}
                                        </Typography>
                                    </Stack>
                                ) : 'Connect'}
                            </Button>
                            <Button color='secondary' variant="outlined" size="small" sx={{ minHeight: 0, maxHeight: 20, minWidth: 0, padding: 1, marginLeft: "auto", marginRight: '5px' }}>
                                <Typography variant='overline' sx={{ fontSize: 10 }}>
                                    SEPOLIA
                                </Typography>
                            </Button>
                        </Stack>

                        <Box sx={{ maxWidth: 40, maxHeight: 3 }}>
                        

                        <Stack direction="column" spacing={1}>
                        <MainMenu
                                switchPC={switchPC}
                                erc721OptionAddress={erc721OptionAddress}
                                fakeBaycAddress={fakeBaycAddress}
                                mode={mode}
                            />
                            <Box style={{marginLeft:'5px', marginTop:'-5px'}}>
                            <DarkModeSwitch
                                onChange={handleMode}
                                checked={mode=="light"}
                                />
                            </Box>
                           
                        </Stack>
                            
                        </Box>
                    </Grid>
                </div>
            </Toolbar>
        </AppBar>
    );
}